import c from 'classnames';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import React from 'react';

type IHeaderHamburgerProps = {
    click(): void;
    isOpen: boolean;
} & WithStyles<typeof styles>;

const HeaderHamburger: React.FC<IHeaderHamburgerProps> = ({ classes, click, isOpen }) => (
    <button
        className={c(classes.hamburger, classes.hamburgerBoring, { [classes.hamburger__isActive]: isOpen })}
        onClick={click}
        type="button"
    >
        <span className={classes.hamburgerBox}>
            <span className={classes.hamburgerInner} />
        </span>
    </button>
);

const styles = createStyles({
    hamburger: {
        display: 'inline-block',
        cursor: 'pointer',
        transitionProperty: 'opacity, filter',
        transitionDuration: '0.15s',
        transitionTimingFunction: 'linear',
        font: 'inherit',
        color: 'inherit',
        textTransform: 'none',
        backgroundColor: 'transparent',
        border: 0,
        margin: 0,
        overflow: 'visible',
        outline: 'none',

        '&:hover': {
            opacity: 0.7,
        },
    },
    hamburgerBox: {
        width: 30,
        height: '24px',
        display: 'inline-block',
        position: 'relative',
        marginTop: '6px',
    },
    hamburgerInner: {
        display: 'block',
        top: '50%',
        marginTop: '-2px',
        width: 30,
        height: 3,
        backgroundColor: '#333',
        borderRadius: 8,
        position: 'absolute',
        transitionProperty: 'transform',
        transitionDuration: '0.15s',
        transitionTimingFunction: 'ease',
        opacity: 0.8,

        '&::before, &::after': {
            width: 30,
            height: 3,
            backgroundColor: '#333',
            borderRadius: 8,
            position: 'absolute',
            transitionProperty: 'transform',
            transitionDuration: '0.15s',
            transitionTimingFunction: 'ease',
            content: '""',
            display: 'block',
        },

        '&:before': {
            top: -8,
        },

        '&:after': {
            bottom: -8,
        },
    },
    hamburgerBoring: {
        display: 'block',

        '& $hamburgerInner': {
            transitionProperty: 'none',

            '&::before, & ::after': {
                transitionProperty: 'none',
            },
        },
    },
    hamburger__isActive: {
        display: 'block',

        '&:hover': {
            opacity: 0.7,
        },

        '& $hamburgerInner': {
            backgroundColor: '#333',
            transform: 'rotate(45deg)',

            '&::before': {
                backgroundColor: '#333',
                top: 0,
                opacity: 0,
            },

            '&::after': {
                bottom: 0,
                transform: 'rotate(-90deg)',
            },
        },
    },
});

export default injectSheet(styles)(HeaderHamburger);
