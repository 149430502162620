
import withStyles, { CSSProperties, WithStylesOptions, IWithStyleHoc } from '@material-ui/styles/withStyles';
import { ComponentNames, ITheme } from 'core/style/interfaces';
import merge from 'deepmerge';

export { createStyles } from '@material-ui/styles';

const themeRedefinitionsLevels = (
    styles: Record<string, CSSProperties> | ((theme: ITheme) => Record<string, CSSProperties>),
    componentName?: ComponentNames,
) => (theme: ITheme) => {
    const result = typeof styles === 'function' ? styles(theme) : styles;

    if (componentName && componentName in theme.componentStyles) {
        const { mode = 'partial', style: externalStyles } = theme.componentStyles[componentName];

        const computedStyle = typeof externalStyles === 'function' ? externalStyles(theme) : externalStyles;

        if (mode === 'full') {
            return computedStyle;
        }

        if (mode === 'partial') {
            return merge(result, computedStyle);
        }
    }

    return result;
};

const injectSheet = <TStyle>(
    style: TStyle,
    name?: ComponentNames,
    options?: WithStylesOptions,
): IWithStyleHoc<TStyle> => withStyles(themeRedefinitionsLevels(style as any, name), options);

export default injectSheet;
