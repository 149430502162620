import { parse as parseQueryString, stringify as stringifyQueryString } from 'query-string';
import { History, Location } from 'history';

export const parseLocationQuery = (location: Location) => parseQueryString(location.search);

export function changeQuery(history: History, location: Location, newQuery: any) {
    history.push({
        pathname: location.pathname,
        search: stringifyQueryString({ ...parseLocationQuery(location), ...newQuery }),
    });
}

/**
 * В отличии от changeQuery, которая перекрывает query новыми данными, оставляя при этом непришедшие,
 * overwriteQuery полностью стирает и записывает новый query
 * @param saveHistory сохраняет историю изменения query
 */
export function overwriteQuery(history: History, location: Location, newQuery: any, saveHistory = true) {
    history[saveHistory ? 'push' : 'replace']({
        pathname: location.pathname,
        search: stringifyQueryString({ ...newQuery }),
    });
}

/**
 * Удаляет оборачивающие кавычки(если есть) у всех полей объекта или строки
 * Пример: "test" => test
 */
export const deleteWrappingQuotes = <T extends string | object>(object: T): T => {
    if (!object) {
        return object;
    }

    if (typeof object === 'string') {
        if (object[0] === '"' && object[object.length - 1] === '"') {
            return object.slice(1, object.length - 1) as T;
        }

        return object;
    }

    return Object.keys(object).reduce((result, key) => {
        const data = object[key];

        if (typeof data === 'string' && data[0] === '"' && data[data.length - 1] === '"') {
            result[key] = data.slice(1, data.length - 1);
        } else {
            result[key] = data;
        }

        return result;
    }, {}) as T;
};
