import { CSSProperties, ITheme } from '@material-ui/styles';

export const headerHeight = 96;
export const mobileHeaderHeight = headerHeight - 20;

const commonButtonStyles: CSSProperties = {
    fontSize: 16,
    height: 40,
    minWidth: 120,
    padding: '0 15px',
    border: 'none',
    cursor: 'pointer',
    margin: 'auto 0',
};

export const loginButtonStyles: (theme: ITheme) => CSSProperties = theme => ({
    ...commonButtonStyles,
    background: 'transparent',
    color: theme.colors.textBase,
    marginRight: 20,
    marginLeft: 10,
    border: '1px solid rgba(218, 218, 218, 0.46)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',

    '&:hover': {
        background: theme.colors.lightGray,
    },
});

export const signupButtonStyles: (theme: ITheme) => CSSProperties = theme => ({
    ...commonButtonStyles,
    background: theme.colors.accent,
    color: '#fff',

    '&:hover': {
        background: theme.colors.accentDark,
    },
});
