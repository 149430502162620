import { ISettingsContext, useSettings } from 'core/components/settingsContext';
import { useFiltersFromUrl } from 'core/hooks/useFiltersFromUrl';
import { useMemo } from 'react';
import { ProductFormListRequest, ProductFormOrderEnum, ProductFormPublicTypeEnum } from 'site/graphql';

type IFiltersFromUrl = {
    types?: any;
    isFree: string;
    onlyFree: string;
    onlyPaid: string;
    complexity: string;
    order: string;
    tags: any;
    lecturers: any;
    direction: string;
};

export const DEFAULT_TAKE = 12;
export const DEFAULT_SKIP = 0;

/**
 * Вспомогательная функция позволяющая извлечь значение для фильтра с правильным приоритетом
 * Приоритеты
 * 1. Значение в настройках витрины или сайта
 * 2. Значение в урл
 * 3. Значение по умолчанию, если в предыдущих источниках ничего нет
 * @param value Значение фильтра из урла
 * @param settings Настройки витрины или сайта
 * @param filterName Название фильтра в настройках
 * @param defaultValue Значение по умолчанию
 */
const getWithSettings = (value: any, settings: ISettingsContext, filterName: string, defaultValue?: any) => {
    // Значение из настроек витрины или сайта
    const settingValue = settings.coursesList.filter[filterName];

    // Если нет значения в настройках, берем из урла
    let resultValue = settingValue !== undefined ? settingValue : value;

    // Если нет ни в настройках, ни в урле, берем дефолтное
    resultValue = resultValue !== undefined ? resultValue : defaultValue;

    return resultValue;
};

/** Валидные значения: ["WEBINAR", "SEMINAR"], "MATERIAL" */
const checkTypes = (types: any): ProductFormPublicTypeEnum[] => {
    const defaultTypes = [ProductFormPublicTypeEnum.Webinar, ProductFormPublicTypeEnum.Seminar];
    const availableValues = Object.values(ProductFormPublicTypeEnum);

    if (Array.isArray(types)) {
        return types.every(type => availableValues.includes(type)) ? types : defaultTypes;
    } else {
        return availableValues.includes(types) ? types : defaultTypes;
    }
};

/** Валидные значения: [1, 2, 3], 1 */
const checkNumberArray = (value: any) => {
    if (Array.isArray(value)) {
        const parsedArray = value.map(item => parseInt(item, 10));
        return parsedArray.every(item => !isNaN(item)) ? parsedArray : undefined;
    } else {
        const parsedValue = parseInt(value, 10);
        return isNaN(parsedValue) ? undefined : parsedValue;
    }
};

const useCourseListFilters = () => {
    const settings = useSettings();

    // Достаем фильтры из урла
    const {
        types,
        isFree,
        complexity,
        order,
        tags,
        direction,
        lecturers,
        onlyFree,
        onlyPaid,
    } = useFiltersFromUrl<IFiltersFromUrl>();
    const correctTypes = checkTypes(types);
    const correctLecturers = checkNumberArray(lecturers);
    const correctTags = checkNumberArray(tags);

    // Собираем фильтры
    const filter = useMemo<ProductFormListRequest>(
        () => ({
            types: correctTypes,
            isFree: getWithSettings(isFree, settings, 'isFree'),
            onlyFree: getWithSettings(onlyFree, settings, 'onlyFree'),
            onlyPaid: getWithSettings(onlyPaid, settings, 'onlyPaid'),
            order: getWithSettings(
                order,
                settings,
                'order',
                correctTypes.includes(ProductFormPublicTypeEnum.Webinar)
                    ? ProductFormOrderEnum.StartDate
                    : ProductFormOrderEnum.Id,
            ),
            complexity: getWithSettings(complexity, settings, 'complexity'),
            tags: getWithSettings(correctTags, settings, 'tags'),
            lecturers: getWithSettings(correctLecturers, settings, 'lecturers'),
            take: DEFAULT_TAKE,
            skip: DEFAULT_SKIP,
            direction: getWithSettings(direction, settings, 'direction'),
            contentProviderId: settings.contentProviderId,
            showPublic: settings.coursesList.filter.showPublic,
            includeWords: getWithSettings(undefined, settings, 'includeWords'),
            excludeWords: getWithSettings(undefined, settings, 'excludeWords'),
            onlyAvailable: true,
            onlyWithPlaces: true,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [correctTypes, isFree, complexity, order, correctTags, direction, correctLecturers, onlyFree, onlyPaid],
    );

    return filter;
};

export default useCourseListFilters;
