import { ApolloError } from 'apollo-boost';
import { trackJoinSuccess } from 'core/tracking';
import { getErrors, IApolloError } from 'core/utils/apollo';
import gql from 'graphql-tag';
import { useState } from 'react';
import { InviteLink, useCreateOrderMutation } from 'site/graphql';

gql`
    mutation CreateOrder($order: OrderPublicInsertInput!) {
        createOrder(order: $order) {
            id
            productForm {
                id
                paymentStatus
                type
                price
                order {
                    id
                }
                product {
                    id
                    name
                }
            }
            inviteLink {
				id
                dateExpire
                date
                link
                accountId
                orderId
            }
        }
    }
`;

const useCreateOrder = (productFormId: number, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    const [createOrderErrors, setCreateOrderErrors] = useState<IApolloError[]>([]);
    const [inviteLink, setInviteLink] = useState<InviteLink | null>(null);

    const [createOrder] = useCreateOrderMutation({
        variables: {
            order: {
                formId: productFormId,
                registeredFromSite: window.location.href,
            },
        },
        onError: (error: ApolloError) => {
            setLoading(false);
            setCreateOrderErrors(getErrors(error));
        },
        onCompleted: response => {
            setLoading(false);
            setInviteLink(response.createOrder.inviteLink);
            trackJoinSuccess();
        },
    });

    return [createOrder, createOrderErrors, inviteLink] as const;
};

export default useCreateOrder;
