import { CurrencyEnum, PromoCodeAdminTypeEnum, SubscriptionPublicTypeEnum } from 'site/graphql';

// ============ Служебные функции

export const invertMapper = <TKeys extends string, TValue extends string, TConvKey = TKeys>(
    obj: Record<TKeys, TValue>,
    keyConverter?: (key: TKeys) => TConvKey,
): Record<TValue, TKeys> => {
    return Object.keys(obj).reduce((result, key: TKeys) => {
        const value = obj[key];
        result[value] = keyConverter ? keyConverter(key) : key;
        return result;
    }, {} as any);
};

export const codeByValue = <TKeys extends string, TValue>(obj: Record<TKeys, TValue>, value: TValue): TKeys => {
    return Object.keys(obj).filter(k => obj[k] === value)[0] as TKeys;
};

// ============ Служебные функции

// ============ Константы и мапперы

export const IMPOSSIBLE_DATE = '3000-01-01T03:00:00Z' as const;

type IProductComplexity = '0' | '5' | '10';
type IProductComplexityValues = 'Новичок' | 'Средний' | 'Профессионал';

export const ProductComplexity: Record<IProductComplexity, IProductComplexityValues> = {
    '0': 'Новичок',
    '5': 'Средний',
    '10': 'Профессионал',
};

export const ProductComplexityInvert = invertMapper(ProductComplexity, k => parseInt(k));

type IProductVisibilityValues =
    | 'Только купившим'
    | 'Только на сайте контент-провайдера'
    | 'Публичный'
    | 'Архив'
    | 'По ссылке';
export type IProductVisibility = 'ADMIN' | 'PUBLIC' | 'ARCHIVED' | 'LINK';

export const ProductVisibility: Record<IProductVisibility, IProductVisibilityValues> = {
    ADMIN: 'Только купившим',
    PUBLIC: 'Публичный',
    ARCHIVED: 'Архив',
    LINK: 'По ссылке',
};

export const ProductVisibilityInvert = invertMapper(ProductVisibility);

export type IProductFormTypeFull =
    | 'WEBINAR'
    | 'SEMINAR'
    | 'MATERIAL'
    | 'EVENT_RECORD'
    | 'VIDEO_COURSE'
    | 'CERTIFICATE'
    | 'OFFER';
type IProductFormTypeFullValues =
    | 'Вебинар'
    | 'Семинар'
    | 'Материал'
    | 'Видеозапись'
    | 'Видеокурс'
    | 'Сертификат'
    | 'Комплект';

export const ProductFormTypeFull: Record<IProductFormTypeFull, IProductFormTypeFullValues> = {
    WEBINAR: 'Вебинар',
    SEMINAR: 'Семинар',
    MATERIAL: 'Материал',
    EVENT_RECORD: 'Видеозапись',
    VIDEO_COURSE: 'Видеокурс',
    CERTIFICATE: 'Сертификат',
    OFFER: 'Комплект',
};

export const ProductFormTypeFullInvert = invertMapper(ProductFormTypeFull);

export const ProductFormTypeFullOrder: Record<IProductFormTypeFull, number> = {
    WEBINAR: 2,
    SEMINAR: 1,
    MATERIAL: 6,
    EVENT_RECORD: 4,
    VIDEO_COURSE: 3,
    CERTIFICATE: 5,
    OFFER: 7,
};

// PromoCode

type IPromoCodeTypeValues = 'По проценту от стоимости' | 'Фиксированное значение';

export const PromoCodeType: Record<PromoCodeAdminTypeEnum, IPromoCodeTypeValues> = {
    PERCENT: 'По проценту от стоимости',
    AMOUNT: 'Фиксированное значение',
};

export const PromoCodeTypeInvert = invertMapper(PromoCodeType);

// Subscription

type ISubscriptionTypeValues =
    | '[Старая] Базовая'
    | '[Старая] Корпоративные облигации'
    | 'Базовая на месяц'
    | 'Базовая на год';

export const SubscriptionType: Record<SubscriptionPublicTypeEnum, ISubscriptionTypeValues> = {
    FREE_COURSES: '[Старая] Базовая',
    CORPORATE_BONDS: '[Старая] Корпоративные облигации',
    MONTH: 'Базовая на месяц',
    YEAR: 'Базовая на год',
};

export const SubscriptionTypeInvert = invertMapper(SubscriptionType);

export const SubscriptionPaymentType = {
    'Подарочная подписка': 'GIFT',
    'Платная подписка': 'PAID',
};

export const DEFAULT_SOON_CAUSE = 'Идёт набор группы';

// Currency

type ICurrencyEnumValues = 'RUB' | 'USD';

export const CurrencyEnumType: Record<CurrencyEnum, ICurrencyEnumValues> = {
    RUB: 'RUB',
    USD: 'USD',
};

export const CurrencyEnumTypeInvert = invertMapper(CurrencyEnumType);

// ============ Константы и мапперы
