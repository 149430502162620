import { useState, useCallback, useRef, useEffect } from 'react';

const ESC_KEY = 27;

const onEscapeKeyPress = (fn: () => void) => ({ keyCode }: KeyboardEvent) => (keyCode === ESC_KEY ? fn() : null);

/**
 * Fork https://github.com/dprovodnikov/useDropdown
 *
 * Usage: const [containerRef, isOpen, toggle, open, close] = useDropdown();
 */
const useDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);

    const open = useCallback(() => setIsOpen(true), []);
    const close = useCallback(() => setIsOpen(false), []);
    const toggle = useCallback(() => setIsOpen(state => !state), []);

    useEffect(() => {
        const handleGlobalMouseDown = ({ target }) => {
            if (!ref.current || ref.current.contains(target)) {
                return;
            }

            close();
        };

        const handleGlobalKeydown = onEscapeKeyPress(close);

        document.addEventListener('mousedown', handleGlobalMouseDown);
        document.addEventListener('keydown', handleGlobalKeydown);

        return () => {
            document.removeEventListener('mousedown', handleGlobalMouseDown);
            document.removeEventListener('keydown', handleGlobalKeydown);
        };
    }, [close]);

    return [ref, isOpen, toggle, open, close] as const;
};

export default useDropdown;
