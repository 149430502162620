import c from 'classnames';
import { useLinks } from 'core/components/linksContext';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import { NavLink } from 'react-router-dom';
import HeaderAuth from './HeaderAuth';

type IHeaderMenuProps = {} & WithStyles<typeof styles>;

const HeaderMenu: React.FC<IHeaderMenuProps> = ({ classes }) => {
    const links = useLinks();

    const MenuLink = ({ title, url }) => (
        <NavLink to={url} className={c(classes.hideIfMobile, classes.menuItem)} activeClassName="active">
            {title}
        </NavLink>
    );

    return (
        <div className={classes.menu}>
            <MenuLink url={links.courseList.url()} title="Курсы" />
            <MenuLink url={links.articles.url()} title="Статьи" />
            <MenuLink url={links.servicePage.url()} title="Сервисы инвестора" />
            <HeaderAuth />
        </div>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        menu: {
            display: 'flex',
            height: 100 + '%',
            order: 3,
        },
        menuItem: {
            textDecoration: 'none',
            fontSize: 16,
            color: theme.colors.textBase,
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            padding: '0 10px',

            '&.active': {
                color: theme.colors.accent,

                '&::after': {
                    content: '""',
                    position: 'absolute',
                    borderBottom: `${theme.colors.accentMenu} 4px solid`,
                    width: 100 + '%',
                    bottom: 0,
                    left: 0,
                },
            },

            '&:hover, &:focus, &:active': {
                color: theme.colors.accent,
            },

            '&:last-of-type': {
                marginRight: 25,
                paddingRight: 0,
            },
        },
        hideIfMobile: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        hideIfDekstop: {
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
    });

export default injectSheet(styles)(HeaderMenu);
