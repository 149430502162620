import c from 'classnames';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
// import FbIcon from '../../svg/facebook.svg';
// import InstagramIcon from '../../svg/instagram.svg';
import TelegramIcon from '../../svg/telegram.svg';
import TwitterIcon from '../../svg/twitter.svg';
import VkIcon from '../../svg/vk.svg';
import YouTubeIcon from '../../svg/youtube.svg';

const styles = (theme: ITheme) =>
    createStyles({
        container: {
            padding: '10px 0',
        },
        link: {
            margin: '0',
            marginRight: '10px',
        },
        icon: {
            width: 1.5 + 'rem',
            height: 1.5 + 'rem',
            fillOpacity: 0.68,

            '&:hover, &:active': {
                fillOpacity: 1,
            },

            [theme.breakpoints.down('xs')]: {
                width: 2 + 'rem',
                height: 2 + 'rem',
            },
        },
        iconDark: {
            fill: theme.colors.contrastBgText,
        },
        iconLight: {
            fill: theme.colors.textBase,
        },
    });

interface ISocialIconsProps {
    mode: 'light' | 'dark';
}

class SocialIcons extends React.Component<ISocialIconsProps & WithStyles<typeof styles>> {
    renderIcon(href: string, Icon: React.StatelessComponent<IIconProps>) {
        const { classes, mode } = this.props;

        return (
            <a className={classes.link} target="_blank" rel="noopener noreferrer" href={href}>
                <Icon
                    className={c(classes.icon, {
                        [classes.iconDark]: mode === 'dark',
                        [classes.iconLight]: mode === 'light',
                    })}
                />
            </a>
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.container}>
                {this.renderIcon('https://vk.com/redcircule', VkIcon)}
                {this.renderIcon('https://www.youtube.com/c/red-circule', YouTubeIcon)}
                {/* {this.renderIcon('https://www.facebook.com/redcircule/', FbIcon)} */}
                {this.renderIcon('https://twitter.com/red_circule', TwitterIcon)}
                {/* {this.renderIcon('https://www.instagram.com/red_circule/', InstagramIcon)} */}
                {this.renderIcon('https://t.me/redcircule', TelegramIcon)}
            </div>
        );
    }
}

export default injectSheet(styles)(SocialIcons);
