import { ThemeProvider } from '@material-ui/styles';
import createTheme from 'core/style/createTheme';
import React from 'react';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1170,
            xl: 1920,
        },
    },
});

const SiteThemeProvider = props => <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;

export default SiteThemeProvider;
