import React from 'react';
import { Helmet } from 'react-helmet';
import { gql } from 'apollo-boost';
import { useGetSeoQuery, GetSeoQuery } from 'site/graphql';
import { useLocation } from 'react-router-dom';
import { getPictureUrl } from 'core/api/pictures';

const DEFAULT_SEO: GetSeoQuery['seoPageInfoByPathname'] = {
    id: 0,
    pathname: '/default',
    title:
        'Обучение финансам, трейдингу, инвестициям и торговле на бирже. Красный Циркуль - обучение для начинающих и профи',
    description:
        'Красный Циркуль проводит курсы, необходимые начинающим и опытным инвесторам, обучающие торговле на финансовом рынке. Получите информацию для грамотного инвестирования средств!',
    keywords: 'обучение торговле на валютном рынке, на фондовой бирже, на московской бирже, рынке, для начинающих',
};

export const getSeoImage = (seo: GetSeoQuery['seoPageInfoByPathname']) => {
    return seo.pictureId
        ? getPictureUrl(seo.pictureId, '300x300', null)
        : 'https://red-circule.com/favicon/android-chrome-256x256.png';
};

gql`
    query GetSeo($pathname: String!) {
        seoPageInfoByPathname(pathname: $pathname) {
            id
            pathname
            title
            keywords
            description
            pictureId
        }
    }
`;

const Seo: React.FC = () => {
    const location = useLocation();
    const { data } = useGetSeoQuery({ variables: { pathname: location.pathname } });
    const seo = (data && data.seoPageInfoByPathname && data.seoPageInfoByPathname) || DEFAULT_SEO;

    return (
        <Helmet>
            <title>{seo.title}</title>
            <meta name="description" property="og:description" content={seo.description}></meta>
            <meta name="keywords" content={seo.keywords}></meta>
            <meta name="image" property="og:image" content={getSeoImage(seo)}></meta>
        </Helmet>
    );
};

export default Seo;
