import draftToHtml from 'draftjs-to-html';
import smoothScroll from 'smooth-scroll';

export function isHtmlEmpty(html: string): boolean {
    return !html || html === '<p><br></p>' || html === '<p></p>' || html === '<p> </p>' || html === '<p></p>\n';
}

export function stripTags(html: string) {
    return html && html.replace(/(<([^>]+)>)/gi, '');
}

export const getRichContent = (value: string): string => {
    try {
        return draftToHtml(JSON.parse(value));
    } catch (error) {
        return '';
    }
};

export const scrollToTop = () => {
    const scroll = new smoothScroll();
    scroll.animateScroll(document.body);
};
