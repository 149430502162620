import { withRouter, RouteComponentProps } from 'react-router';
import React from 'react';
import { Fragment } from 'react';
import { ifBrowser } from 'core/utils';

class ScrollToTop extends React.Component<RouteComponentProps<any>> {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            ifBrowser(() => window.scrollTo(0, 0));
        }
    }

    render() {
        return this.props.children ? <Fragment>{this.props.children}</Fragment> : null;
    }
}

export default withRouter(ScrollToTop);
