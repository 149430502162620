import ExpansionPanel from 'core/components/ExpansionPanel';
import { button, buttonAccent, fieldInput } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';

type IPromoCodeExpansionPanelProps = {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    errorMessage?: string;
    wrapperStyles?: React.CSSProperties;
} & WithStyles<typeof styles>;

type IPromoCodeExpansionPanelState = {
    expanded: boolean;
};

class PromoCodeExpansionPanel extends React.Component<IPromoCodeExpansionPanelProps, IPromoCodeExpansionPanelState> {
    state: IPromoCodeExpansionPanelState = {
        expanded: false,
    };

    handleClick = (event: React.MouseEvent<HTMLElement>, expanded: boolean) => this.setState({ expanded });

    render() {
        const { classes, value, onChange, onSubmit, errorMessage, wrapperStyles } = this.props;
        const { expanded } = this.state;

        return (
            <div className={classes.container} style={wrapperStyles}>
                <ExpansionPanel
                    expanded={expanded}
                    onClick={this.handleClick}
                    summary={<div className={classes.header}>У меня есть промокод!</div>}
                    details={
                        <React.Fragment>
                            <div className={classes.form}>
                                <input
                                    type="text"
                                    onChange={onChange}
                                    value={value || ''}
                                    className={classes.input}
                                    placeholder="Введите промокод"
                                />
                                <button className={classes.submit} onClick={onSubmit} disabled={!value}>
                                    Применить
                                </button>
                            </div>
                            {errorMessage && <div className={classes.error}>{errorMessage}</div>}
                        </React.Fragment>
                    }
                />
            </div>
        );
    }
}

const styles = (theme: ITheme) =>
    createStyles({
        container: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: -1 + 'rem',
                marginRight: -1 + 'rem',
            },
        },
        input: {
            ...fieldInput,
            height: 38,
            flex: 1,

            [theme.breakpoints.down('sm')]: {
                padding: 14,
            },
        },
        submit: {
            ...button(theme),
            ...buttonAccent(theme),
            flex: 1,
            marginLeft: 20,

            [theme.breakpoints.down('sm')]: {
                margin: '1rem -0.5rem 0 -0.5rem',
            },

            '&:hover, &:focus': {
                color: 'white',
                backgroundColor: theme.colors.contrastBg,
                boxShadow: 'none',
            },
        },
        error: {
            color: theme.colors.accentLight,
            fontSize: 14,
            textAlign: 'center',
        },
        header: {
            color: theme.colors.accent,
            fontWeight: 'bold',
            textAlign: 'center',
            paddingTop: 0.5 + 'rem',
            paddingBottom: 0.5 + 'rem',
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
            padding: '8px 24px 24px',

            [theme.breakpoints.down('sm')]: {
                padding: '0 24px',
                flexDirection: 'column-reverse',
            },
        },
        form: {
            display: 'flex',
            padding: 0.5 + 'rem',

            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
    });

export default injectSheet(styles, 'PromoCodeExpansionPanel')(PromoCodeExpansionPanel);
