import { ApolloProvider } from '@apollo/react-hooks';
import { StylesProvider } from '@material-ui/styles';
import { ApolloClient, ApolloLink, InMemoryCache } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { ServerError } from 'apollo-link-http-common';
import generateClassName from 'core/style/generateClassName';
import React from 'react';
import { Renderer } from 'react-dom';
import Loadable from 'react-loadable';
import { BrowserRouter } from 'react-router-dom';
import { GetMyAccountDocument, GetMyAccountQuery, GetMyAccountQueryVariables } from 'site/graphql';
import SiteThemeProvider from 'site/style/SiteThemeProvider';
import App from '../App';
import hasSentry from './initSentry';

if (hasSentry) {
    // eslint-disable-next-line no-console
    console.log('sentry initialized');
}

const client = new ApolloClient({
    link: ApolloLink.from([
        onError(({ networkError }) => {
            // Обработка 401 статуса
            if (networkError && (networkError as ServerError).statusCode === 401) {
                client.writeQuery<GetMyAccountQuery, GetMyAccountQueryVariables>({
                    query: GetMyAccountDocument,
                    data: {
                        myAccount: null,
                    },
                });
            }
        }),
        createHttpLink({
            uri: '/api/graphql/public',
            credentials: 'same-origin',
        }),
    ]),
    cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
    /**
     * Можно выставить задержку больше, чем указано в документации, т.к в этот промежуток
     * запросы с fetchPolicy 'network-only' и 'cache-and-network' будут работать как 'cache-first':
     * https://github.com/apollographql/apollo-client/blob/f26b98cc8ad859bef4ec74d77fd4245b022cc60d/src/ApolloClient.ts#L288
     * Что не очень страшно, даже если в этот промежуток пользователь переключиться между вкладками
     */
    ssrForceFetchDelay: 1000,
    defaultOptions: {
        watchQuery: {
            errorPolicy: 'all',
        },
    },
});

let render: Renderer;
if (DEV_CLIENT_ONLY) {
    const { render: reactDomRender } = require('react-dom');
    render = reactDomRender;
} else {
    const { hydrate } = require('react-dom');
    render = hydrate;
}

Loadable.preloadReady().then(() => {
    render(
        <ApolloProvider client={client}>
            <BrowserRouter>
                <StylesProvider generateClassName={generateClassName()}>
                    <SiteThemeProvider>
                        <App />
                    </SiteThemeProvider>
                </StylesProvider>
            </BrowserRouter>
        </ApolloProvider>,
        document.getElementById('app'),
        () => {
            // удаляем стили пришедшие с сервера
            const ssStyles = document.getElementById('server-side-styles');
            if (ssStyles) {
                ssStyles.parentNode.removeChild(ssStyles);
            }
        },
    );
});
