import { ITheme } from 'core/style/interfaces';
import { globalFontSize } from './index';
import injectSheet, { createStyles } from './injectSheet';

const global = (theme: ITheme) =>
    createStyles({
        '@global': {
            html: {
                fontFamily: 'sans-serif',
                fontSize: 16,
                lineHeight: 24 + 'px',
                color: theme.colors.black,

                [theme.breakpoints.up('sm')]: {
                    fontSize: globalFontSize,
                    lineHeight: 27 + 'px',
                },
            },
            body: {
                margin: 0,
            },
            'p, ul, ol, pre, table, blockquote': {
                marginTop: 0,
                marginBottom: 27,
            },
            a: {
                color: theme.colors.linkOld,
                textDecoration: 'none',

                '&:hover, &:focus': {
                    color: theme.colors.linkOldHover,
                },
            },
            '::-moz-selection': {
                background: theme.colors.turquoise,
                color: theme.colors.contrastBgText,
            },
            '::selection': {
                background: theme.colors.turquoise,
                color: theme.colors.contrastBgText,
            },
            'span.ruble-icon': {
                fontFamily: '"Helvetica Neue", sans-serif',
            },
            'input[type=search]': {
                // clears the 'X' from Internet Explorer
                '&::-ms-clear, &::-ms-reveal': {
                    display: 'none',
                    width: 0,
                    height: 0,
                },

                // clears the 'X' from Chrome
                '&::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration': {
                    display: 'none',
                },
            },
        },
    });

export default injectSheet(global)(() => null);
