import { CSSProperties, ITheme } from 'core/style/interfaces';

export const globalFontSize = 18;
export const remCalc = (px: number) => `${px / globalFontSize}rem`;

export const container = (theme: ITheme, { gutter = 15 } = {}) => ({
    maxWidth: 320,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: `0 ${gutter}px 0 ${gutter}px`,

    [theme.breakpoints.up('sm')]: {
        maxWidth: theme.breakpoints.values.lg,
    },
});

export const columns: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 'initial',
};

type IRowOptions = {
    gutter?: number;
    flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
};

export const row = ({ gutter = 15, flexWrap = 'wrap' }: IRowOptions = {}): CSSProperties => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap,
    marginLeft: `-${gutter}px`,
    marginRight: `-${gutter}px`,
});

type IColumnOptions = {
    base?: string;
    gutter?: number;
    cols?: number;
    grow?: number;
    shrink?: number;
};

export const column = ({
    base = null,
    gutter = 15,
    cols = 12,
    grow = 0,
    shrink = 0,
}: IColumnOptions = {}): CSSProperties => {
    const calculatedBase = base ? base : `${(100 / 12) * cols}%`;
    const maxWidth = calculatedBase === '0px' || calculatedBase === 'auto' ? undefined : calculatedBase;
    return {
        flexBasis: calculatedBase,
        flexGrow: grow,
        flexShrink: shrink,
        maxWidth,
        paddingLeft: `${gutter}px`,
        paddingRight: `${gutter}px`,
        boxSizing: 'border-box',
    };
};

export const button = (theme: ITheme): CSSProperties => ({
    display: 'inline-block',
    verticalAlign: 'middle',
    textAlign: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
    padding: 16,
    lineHeight: 1,
    border: 'none',
    color: theme.colors.textBase,
    backgroundColor: theme.colors.lightBg,
    transition: 'opacity 0.25s ease-out, background 0.25s ease-out, color 0.25s ease-out, box-shadow 0.25s ease-out',

    '&:hover, &:focus': {
        color: theme.colors.textBase,
        backgroundColor: '#E6E6E6',
        boxShadow: 'none',
    },

    '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
});

export const buttonAccent = (theme: ITheme): CSSProperties => ({
    color: 'white',
    backgroundColor: theme.colors.accent,

    '&:hover, &:focus': {
        color: 'white',
        backgroundColor: theme.colors.accentDark,
        boxShadow: 'none',
    },
});

export const buttonBlack = (theme: ITheme): CSSProperties => ({
    color: 'white',
    backgroundColor: theme.colors.black,

    '&:hover, &:focus': {
        color: 'white',
        backgroundColor: theme.colors.footerBg,
        boxShadow: 'none',
    },
});

export const buttonFormInvalid: CSSProperties = {
    cursor: 'not-allowed',
};

export const buttonShadow: CSSProperties = {
    boxShadow:
        'inset -4px -4px 8px 2px rgba(0, 0, 0, 0.1), ' +
        'inset 4px 4px 8px 2px rgba(255, 255, 255, 0.2), ' +
        '0px 1px 4px -1px #333',
};

export const link = (color: string): CSSProperties => ({
    lineHeight: 'inherit',
    color,
    textDecoration: 'none',
    cursor: 'pointer',

    '&:hover': {
        textDecoration: 'underline',
        color,
    },
});

export const reverseLink = (color: string): CSSProperties => ({
    lineHeight: 'inherit',
    color,
    textDecoration: 'underline',
    cursor: 'pointer',

    '&:hover': {
        textDecoration: 'none',
        color,
    },
});

export const icon: CSSProperties = {
    width: 20,
    height: 20,
};

type ITitleSizes = 'h1' | 'h2' | 'h3' | 'h4';
type ITitleModes = 'top' | 'left' | 'bottom' | 'old';

type ITitleOptions = {
    size?: ITitleSizes;
    mode?: ITitleModes;
    color?: string;
};

type ITitleSizesMap = { [key in ITitleSizes]: CSSProperties };

const titleSizes: ITitleSizesMap = {
    h1: {
        fontSize: '2rem',
        lineHeight: '3rem',
        marginBottom: '1.5rem',
    },
    h2: {
        fontWeight: 'bold',
        fontSize: '1.6666667rem',
        lineHeight: '3rem',
        marginBottom: '1.5rem',
    },
    h3: {
        fontWeight: 'bold',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        marginBottom: 0,
    },
    h4: {},
};

export const title = ({ size, mode, color = '#333' }: ITitleOptions = {}): CSSProperties => {
    let result: any = {
        marginTop: 0,
        color,
    };

    switch (mode) {
        case 'left':
            result = {
                ...result,
                ...{
                    position: 'relative',
                    paddingLeft: '24px',

                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        display: 'block',
                        width: '7px',
                        height: '58%',
                        left: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        backgroundColor: color,
                    },
                },
            };
            break;
        case 'top':
        case 'bottom':
            result = {
                ...result,
                ...{
                    position: 'relative',
                    textAlign: 'center',
                    marginTop: '50px',

                    '&::after': {
                        content: '""',
                        display: 'block',
                        height: '6px',
                        width: '84px',
                        margin: '6px auto 0',
                        backgroundColor: color,
                    },
                },
            };
            break;
        case 'old':
            result = {
                ...result,
                ...{
                    position: 'relative',
                    textAlign: 'center',
                    margin: '1.5rem 0 0.5rem 0',
                    fontFamily: '"Trebuchet MS", Helvetica, Arial, sans-serif',
                    fontWeight: 'normal',
                    fontSize: '2.5rem',

                    '&::before': {
                        marginRight: 8,
                        width: '10%',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        borderTop: '1px solid #BBBBBB',
                        content: '""',
                    },

                    '&::after': {
                        marginLeft: 8,
                        width: '10%',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        borderTop: '1px solid #BBBBBB',
                        content: '""',
                    },
                },
            };
            break;
        default:
            break;
    }

    const sizeStyle = titleSizes[size];

    return { ...result, ...sizeStyle };
};

export const field: CSSProperties = {
    fontSize: 16,
    color: '#202020',
    display: 'flex',
    flexDirection: 'column',
};

export const fieldInput: CSSProperties = {
    height: 30,
    lineHeight: '11px',
    padding: '5px 14px',
    border: '1px solid rgb(0, 0, 0, 0.1)',
    borderRadius: 0,
    fontSize: 16,
    outline: 'none',

    '&:hover': {
        border: '1px solid rgb(0, 0, 0, 0.25)',
    },

    '&:focus': {
        border: '1px solid rgb(0, 0, 0, 0.5)',
    },

    '&:disabled': {
        background: 'rgba(230, 230, 230, 0.5)',
        cursor: 'not-allowed',
    },
};

export const fieldInputInvalid: CSSProperties = {
    background: 'rgba(254, 239, 239)',
    border: '1px solid rgb(255, 102, 102)',
};

export const textArea: CSSProperties = {
    border: '1px solid rgb(0, 0, 0, 0.1)',
    outline: 'none',
    '&:focus': {
        border: '1px solid rgb(0, 0, 0, 0.5)',
        // border: '2px solid rgb(141, 185, 247, 1)', - как вариант
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
        background: 'rgba(255, 255, 255)',
    },
    '&:disabled': {
        background: 'rgba(230, 230, 230, 0.5)',
        cursor: 'not-allowed',
    },
};

// ========= animation
export const fading = (id, duration = 0.5) => ({
    style: {
        animation: `${id}Fading ${duration}s ease infinite alternate`,
    },
    keyframes: {
        [`@keyframes ${id}Fading`]: {
            '0%': { opacity: 0.5 },
            '100%': { opacity: 1 },
        },
    },
});

// медиазапрос под IE
export const ieFix = (style: CSSProperties) => ({
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        ...style,
    },

    '@supports (-ms-ime-align:auto)': {
        ...style,
    },
});

// сброс кнопки
export const buttonReset: CSSProperties = {
    border: 0,
    background: 'transparent',
    padding: 0,
};

/**
 * Темный блок заголовка на странице
 * @description Поддерживает 2 уровня `h1` и `h2`
 * @example
 * <div className="pageHeader">
 *     <h1>First level</h1>
 *     <h2>Second level</h2>
 * </div>
 */
export const pageHeader = (theme: ITheme): CSSProperties => ({
    minHeight: 110,
    padding: 24,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: 'white',
    backgroundColor: theme.colors.contrastBg,
    lineHeight: 22 + 'px',

    '& > h1': {
        fontSize: 1.5 + 'rem',
        fontWeight: 'bold',
        margin: 0,
        maxWidth: 800,
    },

    '& > h2': {
        fontSize: 0.8 + 'rem',
        fontWeight: 'normal',
        margin: 0,
        marginTop: 10,
        maxWidth: 800,
    },

    [theme.breakpoints.down('xs')]: {
        minHeight: 80,

        '& > h1': {
            fontSize: 0.9 + 'rem',
        },

        '& > h2': {
            fontSize: 0.7 + 'rem',
            lineHeight: 1 + 'rem',
        },
    },
});

// card
export const card = (theme: ITheme): CSSProperties => ({
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.divider,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
});
