import { isEmpty, isObject } from 'core/utils';
import fetch from 'isomorphic-fetch';

export interface IResponseError {
    PropertyName?: string;
    ErrorMessage: string;
    message?: string;
}

export interface IBaseResponse<T> {
    Result: T;
    Errors: IResponseError[];
}

export type IErrorsCodes = 'forbid-error' | 'not-found-error' | 'bad-request-error' | 'internal-error';

export interface IGenericListResponse<T> extends IBaseResponse<T[]> {
    Count: number;
}

export type IGraphQLResponse<TData = any> = {
    data: TData;
} & IGraphQLErrors &
    IHTTPStatus;

export type IHTTPStatus = {
    httpStatus: number;
};

export interface IGraphQLErrors {
    errors: Array<{
        message: string;
        locations: Array<{
            line: number;
            column: number;
        }>;
    }>;
}

export type IDataTableMap = {
    [id: string]: any;
};

let endPoint: string = LOCAL_SETTINGS.API_ENDPOINT;

export function setEndPoint(ep: string): void {
    endPoint = ep;
}

export function resolveUrl(url: string): string {
    return `${endPoint}/${url}`;
}

type IQueryParams = {
    [prop: string]: string | number | null | undefined;
};

export const getQuery = (obj: IQueryParams): string => {
    const str = [];
    for (const p in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, p) && obj[p] !== undefined && obj[p] !== null) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p].toString()));
        }
    }
    return str.join('&');
};

type ITakeSkipParams = {
    take: number;
    skip?: number;
};

export function getTakeSkipQuery(pagecount = 20, page = 0): ITakeSkipParams {
    return { take: pagecount, skip: page * pagecount };
}

export function getTakeSkipQueryString(pagecount: number, page: number): string {
    return getQuery(getTakeSkipQuery(pagecount, page));
}

export class ResponseError {
    message: string;
    response: Response;
    statusCode: number;

    constructor(message: string, response, statusCode = 500) {
        this.message = message;
        this.response = response;
        this.statusCode = statusCode;
    }
}

export const detectTransportErrors = (response: Response) => {
    if (response.status >= 200 && response.status < 500) {
        return response;
    } else {
        throw new ResponseError(response.statusText, response);
    }
};

export const respToJsonWithCode = (response: Response): Promise<any & IHTTPStatus> => {
    return response.json().then(
        json => ({ ...json, httpStatus: response.status }),
        () => ({ errors: [], httpStatus: response.status }),
    );
};

const isBaseResp = (json: any): json is IBaseResponse<any> & IHTTPStatus =>
    isObject(json) && 'Errors' in json && !isEmpty(json.Errors);

const isGraphQlResp = (json: any): json is IGraphQLResponse<any> & IHTTPStatus =>
    isObject(json) && 'errors' in json && !isEmpty(json.errors);

export type IError = { message: string };

const makeErrorUtils = (code: IErrorsCodes) => {
    const predicate = e => e.message.startsWith(code);
    const has = (errors: IError[]) => errors.some(predicate);
    const filter = (errors: IError[]) =>
        errors.filter(predicate).map(e => ({ message: e.message.replace(`${code}:`, '') }));
    return {
        has,
        filter,
        code,
    };
};

// не наследуем от Error т.к. не работает instanceof
export class ForbidError {
    static utils = makeErrorUtils('forbid-error');
    errors: IError[];
    constructor(errors: IError[]) {
        this.errors = errors ? ForbidError.utils.filter(errors) : [];
    }
}

export class UnauthorizedError {
    errors: IError[];

    constructor(errors: IError[]) {
        this.errors = errors;
    }
}

export class NotFoundError {
    static utils = makeErrorUtils('not-found-error');
    errors: IError[];
    constructor(errors: IError[]) {
        this.errors = NotFoundError.utils.filter(errors);
    }
}

export class BadRequestError {
    errors: IError[];
    json: any;

    constructor(errors: IError[], json: any) {
        this.errors = errors;
        this.json = json;
    }
}

const handleErrors = (json: IHTTPStatus) => {
    let errors = null;

    if (isBaseResp(json)) {
        errors = json.Errors.map(error => ({ message: error.ErrorMessage, ErrorMessage: error.ErrorMessage }));
    }
    if (isGraphQlResp(json)) {
        errors = json.errors;
    }

    if (errors || json.httpStatus >= 400) {
        switch (json.httpStatus) {
            case 401:
                throw new UnauthorizedError(errors);
            case 404:
                throw new NotFoundError(errors);
            case 403:
                throw new ForbidError(errors);
            case 400:
            default:
                throw new BadRequestError(errors, json);
        }
    }

    return json;
};

interface IAuthHeader {
    Cookie: string;
    Authorization?: string;
}

const makeAuthHeader = (cookie?: string): IAuthHeader | {} => {
    return cookie ? { Cookie: cookie } : {};
};

export function getJson<TContract = any>(
    url: string,
    query?: IQueryParams | {},
    cookie: string = null,
): Promise<IBaseResponse<TContract>> {
    const options = {
        credentials: 'include',
        headers: makeAuthHeader(cookie),
    };

    const compiledUrl = resolveUrl(query ? `${url}?${getQuery(query)}` : url);

    if (SERVER_ENV) {
        // eslint-disable-next-line no-console
        console.log('GET api call', compiledUrl);

        Object.assign(options, {
            compress: false,
        });
    }

    return fetch(compiledUrl, options)
        .then(detectTransportErrors)
        .then(respToJsonWithCode)
        .then(handleErrors);
}

export function postText(url: string, data): Promise<string> {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
    };

    return fetch(resolveUrl(url), options).then((response: Response) =>
        response.text().then(text => {
            if (response.status === 200) {
                return text;
            }

            throw new BadRequestError([{ message: text }], { httpStatus: response.status });
        }),
    );
}

export function postJson<TRespContract = any, TReqContract = any>(
    url: string,
    data?: TReqContract,
    resolveUrlFn = resolveUrl,
    cookie: string = null,
): Promise<IBaseResponse<TRespContract>> {
    const options = {
        method: 'POST',
        headers: {
            ...makeAuthHeader(cookie),
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
    };

    return fetch(resolveUrlFn(url), options)
        .then(detectTransportErrors)
        .then(respToJsonWithCode)
        .then(handleErrors);
}

export function postFile<TContract = any>(
    url: string,
    file: File,
    cookie: string = null,
): Promise<IBaseResponse<TContract>> {
    const data = new FormData();
    data.append('file', file);

    const options = {
        method: 'POST',
        headers: makeAuthHeader(cookie),
        credentials: 'include',
        body: data,
    };
    return fetch(resolveUrl(url), options)
        .then(detectTransportErrors)
        .then(respToJsonWithCode)
        .then(handleErrors);
}
