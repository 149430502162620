import c from 'classnames';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import React from 'react';
import CheckIcon from '../../svg/check-simple.svg';
import ErrorIcon from '../../svg/error.svg';
import options from './options';

type ISnackbarMessageType = 'success' | 'error' | 'warning' | 'info';

export type ISnackbarProps = {
    text: string | JSX.Element;
    type: ISnackbarMessageType;
};

type ISnackbarState = {
    show: boolean;
};

class Snackbar extends React.Component<ISnackbarProps & WithStyles<typeof styles>> {
    state: ISnackbarState = {
        show: true,
    };

    componentDidMount() {
        // Show
        setTimeout(() => {
            this.setState({ show: true });
        }, 100); // wait 100ms after the component is called to animate toast.

        // Timeout -1 displays toast as a persistent notification
        if (options.timeout === -1) {
            return;
        }

        // Hide after timeout
        setTimeout(() => {
            this.setState({ show: false });
        }, options.timeout);
    }

    render() {
        const { text, classes, type } = this.props;
        const { show } = this.state;

        return (
            <div
                className={c(classes.container, {
                    [classes.show]: show,
                })}
            >
                <div
                    className={c(classes.content, {
                        [classes.error]: type === 'error',
                        [classes.warning]: type === 'warning',
                        [classes.info]: type === 'info',
                        [classes.success]: type === 'success',
                    })}
                >
                    <div className={classes.icon}>
                        {type === 'error' ? <ErrorIcon /> : <CheckIcon className={classes.successIcon} />}
                    </div>
                    {text}
                </div>
            </div>
        );
    }
}

const styles = createStyles({
    container: {
        position: 'fixed',
        margin: '0 auto',
        right: 0,
        top: options.top,
        left: 0,
        textAlign: 'center',
        zIndex: options.zIndex,
        pointerEvents: 'none',
        transition: 'all ' + options.animationDuration + 'ms ease',
        transform: 'translateY(-100%)',
        maxWidth: 568,
        fontSize: 0.875 + 'rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    show: {
        transform: 'translateY(0)',
    },
    content: {
        display: 'inline-flex',
        alignItems: 'center',
        width: 'auto',
        borderRadius: '0 0 4px 4px',
        padding: '15px 30px',
        pointerEvents: 'all',
        minWidth: 288,

        '@media (max-width: 960px)': {
            borderRadius: '0 0 0 0',
            width: 'calc(100% - 60px)',
            minWidth: 'auto',
        },
    },
    success: {
        backgroundColor: '#64b5f6',
        color: 'white',
    },
    error: {
        backgroundColor: '#e57373',
        color: 'black',
    },
    warning: {
        color: '#333333',
        backgroundColor: '#F5E273',
    },
    info: {
        color: '#FFFFFF',
        backgroundColor: '#4990E2',
    },
    icon: {
        display: 'flex',

        '& svg': {
            width: 28,
            height: 28,
            marginRight: 20,
        },
    },
    successIcon: {
        strokeWidth: 2,
        stroke: '#64b5f6',
        fill: 'white',
    },
});

export default injectSheet(styles)(Snackbar);
