import ScrollToTop from 'core/components/common/ScrollToTop';
import UpButton from 'core/components/common/UpButton';
import { DeviceInfoProvider } from 'core/components/DeviceInfoContext';
import { LinksProvider } from 'core/components/linksContext';
import { ISettingsContext, SettingsProvider } from 'core/components/settingsContext';
import { SnackbarContainer } from 'core/components/snackbar';
import GlobalStyle from 'core/style/GlobalStyle';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import 'normalize.css';
import React from 'react';
import AuthModal from 'site/components/auth/AuthModal';
import Footer from 'site/components/footer/Footer';
import Header from 'site/components/header/Header';
import RegPayModal from 'site/components/reg-pay-stepper/RegPayModal';
import TopBar from 'site/components/top-bar/TopBar';
import { AuthProvider } from '../auth/AuthProvider';
import PinModal from '../bonds-portfolio/tinkoff/PinModal';
import PlaceMarketModal from '../bonds-portfolio/tinkoff/PlaceMarketModal';
import { TinkoffProvider } from '../bonds-portfolio/tinkoff/tinkoffContext';
import Seo from '../seo/Seo';
import { linksContextValue } from './makeRouting';

const styles = createStyles({
    content: {
        margin: 0,
        position: 'relative',
        minHeight: 'calc(100vh - 96px - 7px)', // выступ футера 6px
        padding: '1px 0', // hack для учёта маржинов внутреннего контента
        marginTop: '-1px', // компенсация верхнего падинга
    },
});

const settingsContextValue: ISettingsContext = {
    contentProviderId: 1,
    coursesList: {
        filter: {
            showPublic: true,
        },
    },
    accountPurchasedProducts: {
        setContentProvider: false,
        showPublic: true,
    },
};

const Master: React.FC<WithStyles<typeof styles>> = ({ classes, children }) => {
    return (
        <AuthProvider>
            <SettingsProvider value={settingsContextValue}>
                <TinkoffProvider>
                    <LinksProvider value={linksContextValue}>
                        <DeviceInfoProvider>
                            <ScrollToTop />
                            <GlobalStyle />
                            <Seo />
                            <TopBar />
                            <Header />
                            <section className={classes.content}>{children}</section>
                            <AuthModal />
                            <RegPayModal />
                            <PinModal />
                            <PlaceMarketModal />
                            <SnackbarContainer />
                            <UpButton />
                            <Footer />
                        </DeviceInfoProvider>
                    </LinksProvider>
                </TinkoffProvider>
            </SettingsProvider>
        </AuthProvider>
    );
};

export default injectSheet(styles)(Master);
