import c from 'classnames';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles, ITheme } from 'core/style/interfaces';
import React from 'react';

const styles = (theme: ITheme) =>
    createStyles({
        hint: {
            position: 'relative',
        },
        list: {
            border: '1px solid rgb(0, 0, 0, 0.8)',
            backgroundColor: theme.colors.contrastBg,
            listStyle: 'none',
            paddingLeft: 0,
            position: 'absolute',
            top: 38,
            width: '100%',
            zIndex: 20,
        },
        list__hide: {
            display: 'none',
        },
        listItem: {
            padding: 9,
        },
        listItem__selected: {
            backgroundColor: theme.colors.lightGray,
            color: theme.colors.contrastBg,
        },
    });

interface IEmailWithHintProps {
    value: string;
    error?: string;
    inputClassName?: string;
    labelClassName?: string;
    errorClassName?: string;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
}

interface IEmailWithHintState {
    name: string;
    domains: string[];
    selectedHint: number;
    showHint: boolean;
}

class EmailWithHint extends React.Component<IEmailWithHintProps & WithStyles<typeof styles>, IEmailWithHintState> {
    email: HTMLInputElement;

    state = {
        name: '',
        domains: [],
        selectedHint: 0,
        showHint: false,
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }

    handleClick = event => {
        if (!event.target.closest(this.props.classes.hint)) {
            this.setState({ showHint: false });
        }
    };

    onChange = (event: React.FormEvent<HTMLInputElement>) => {
        const email = event.currentTarget.value;
        const emailStart = email.indexOf('@');
        const emailName = email.slice(0, emailStart);
        const emailDomain = emailStart >= 0 ? email.slice(emailStart + 1) : '';
        const emailDomainSeed = emailStart >= 0 ? email.slice(emailStart + 1, emailStart + 4) : '';
        const domains = [
            'bk.ru',
            'gmail.com',
            'inbox.ru',
            'list.ru',
            'mail.ru',
            'rambler.ru',
            'yandex.ru',
            'ya.ru',
            'yahoo.com',
        ];

        let suggestedDomains = [];
        domains.forEach((domain: string) => {
            const entry = domain.indexOf(emailDomainSeed);
            if (entry === 0 && emailDomainSeed !== '') {
                suggestedDomains.push(domain);
            }
        }, this);

        if (emailDomain === suggestedDomains[0]) {
            suggestedDomains = [];
        }

        this.setState({
            name: emailName,
            domains: suggestedDomains,
            showHint: true,
        });

        this.props.onChange(event);
    };

    fillEmail = (email: string) => {
        this.setState({ domains: [], selectedHint: 0 });

        const event = ({
            currentTarget: {
                name: 'email',
                type: 'email',
                value: email,
            },
        } as any) as React.FormEvent<HTMLInputElement>;

        this.props.onChange(event);
    };

    onKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
        const { domains, name } = this.state;
        let { selectedHint } = this.state;

        switch (event.key) {
            case 'Enter': {
                if (domains.length === 0) {
                    break;
                }
                event.preventDefault();

                this.fillEmail(`${name}@${domains[selectedHint]}`);
                break;
            }
            case 'ArrowUp': {
                event.preventDefault();
                selectedHint--;
                if (selectedHint < 0) {
                    selectedHint = domains.length - 1;
                }

                this.setState({ selectedHint });
                break;
            }
            case 'ArrowDown': {
                event.preventDefault();
                selectedHint++;
                if (selectedHint >= domains.length) {
                    selectedHint = 0;
                }

                this.setState({ selectedHint });
                break;
            }
            // skip default
        }
    };

    onMouseOver = (event: React.MouseEvent<HTMLUListElement>) => {
        this.setState({ selectedHint: Number((event.target as HTMLLIElement).id) });
    };

    onMouseOut = (event: React.MouseEvent<HTMLUListElement>) => {
        if (event.currentTarget.className === this.props.classes.list) {
            this.setState({ selectedHint: null });
        }
    };

    renderHints() {
        const { domains, name, selectedHint, showHint } = this.state;
        const { classes } = this.props;

        if (domains.length === 0) {
            return null;
        }

        if (!showHint) {
            return null;
        }

        return (
            <ul className={classes.list} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
                {domains.map((domain, idx) => {
                    return (
                        <li
                            key={idx}
                            id={idx.toString()}
                            className={c(classes.listItem, { [classes.listItem__selected]: idx === +selectedHint })}
                            onClick={() => {
                                this.fillEmail(`${name}@${domain}`);
                            }}
                        >
                            {domain}
                        </li>
                    );
                })}
            </ul>
        );
    }

    render() {
        const { classes, value, error, onBlur, labelClassName, inputClassName, errorClassName } = this.props;
        return (
            <label className={c(classes.hint, labelClassName)} onKeyDown={this.onKeyDown}>
                <input
                    ref={input => {
                        this.email = input;
                    }}
                    value={value}
                    name="email"
                    type="email"
                    placeholder="Введите email"
                    className={inputClassName}
                    onChange={this.onChange}
                    onFocus={() => this.setState({ showHint: true })}
                    onBlur={onBlur}
                />
                <span className={errorClassName}>{error}</span>
                {this.renderHints()}
            </label>
        );
    }
}

export default injectSheet(styles)(EmailWithHint);
