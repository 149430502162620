import hoistStatics from 'hoist-non-react-statics';
import React from 'react';

export const SettingsContext = React.createContext<ISettingsContext>({ contentProviderId: 1 });

const { Provider, Consumer } = SettingsContext;

export const SettingsConsumer: React.ComponentType<{
    children: (settings: ISettingsContext) => React.ReactNode;
}> = Consumer;

type ISettingsProviderBaseProps = {
    value: ISettingsContext;
};

export const SettingsProvider: React.ComponentType<ISettingsProviderBaseProps> = Provider;

export type ISettingsContext = {
    contentProviderId: number;
    coursesList?: {
        filter: {
            showPublic: boolean;
            isFree?: boolean;
            order?: string;
            complexity?: number;
            tags?: number[];
            lecturers?: number[];
            take?: number;
            skip?: number;
            search?: string;
            includeWords?: string;
            excludeWords?: string;
        };
    };
    accountPurchasedProducts?: {
        setContentProvider: boolean;
        showPublic: boolean;
    };
    endPoint?: string;
    showcaseRefCode?: string;
};

export const useSettings = () => React.useContext(SettingsContext);

export type IWithSettingsProps = {
    settings: ISettingsContext;
};

export function withSettings<T>(BaseComponent: React.ComponentType<T & IWithSettingsProps>) {
    const Wrapper: React.StatelessComponent<T> = (props: T) => (
        <SettingsConsumer>{settings => <BaseComponent {...props} settings={settings} />}</SettingsConsumer>
    );

    Wrapper.displayName = `withSettings(${BaseComponent.displayName || BaseComponent.name})`;

    return hoistStatics(Wrapper, BaseComponent);
}
