import { useLinks } from 'core/components/linksContext';
import { container } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import { Link } from 'react-router-dom';
import RedCirculeLogoFullSvg from '../../svg/red-circule-logo-light.svg';
import { headerHeight, mobileHeaderHeight } from './const';
import HeaderMenu from './HeaderMenu';
import HeaderSearch from './HeaderSearch';

const Header: React.FC<WithStyles<typeof styles>> = ({ classes }) => {
    const links = useLinks();

    return (
        <header className={classes.header}>
            <div className={classes.container}>
                <Link className={classes.logoLinkWrapper} to={links.home.url()} title="Красный Циркуль">
                    <RedCirculeLogoFullSvg className={classes.logo} />
                </Link>
                <HeaderSearch />
                <HeaderMenu />
            </div>
        </header>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        header: {
            backgroundColor: '#ffffff',
            boxShadow: '0 0 8px rgba(0, 0, 0, 0.27)',
            zIndex: 50,
            position: 'relative',
        },
        container: {
            ...container(theme),
            height: headerHeight,
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.down('xs')]: {
                height: mobileHeaderHeight,
                justifyContent: 'space-between',
            },
        },
        logoLinkWrapper: {
            display: 'flex',
            marginRight: 25,
            order: 1,

            [theme.breakpoints.down('xs')]: {
                order: 2,
                marginRight: 0,
            },
        },
        logo: {
            width: 150,
            height: 50,
        },
    });

export default injectSheet(styles)(Header);
