import CenterLoader from 'core/components/common/CenterLoader';
import React from 'react';
import Loadable from 'react-loadable';
import { makeRouting, Master } from 'site/components/master';

const loaderFactory = (loader, moduleName) =>
    Loadable({
        loader,
        loading: () => <CenterLoader />,
        modules: [moduleName],
    });

const ProductDetail = loaderFactory(
    () => import(/* webpackChunkName: "ProductDetail" */ 'site/components/product/ProductDetailContainer'),
    'ProductDetail',
);
const ProductDetailStart = loaderFactory(
    () => import(/* webpackChunkName: "ProductDetail" */ 'site/components/product/ProductDetailStartContainer'),
    'ProductDetailStart',
);
const CourseList = loaderFactory(
    () => import(/* webpackChunkName: "CourseList" */ 'site/components/courses/CourseList'),
    'CourseList',
);
const Main = loaderFactory(() => import(/* webpackChunkName: "Main" */ 'site/components/main/Main'), 'Main');
const NotFound = loaderFactory(
    () => import(/* webpackChunkName: "NotFound" */ 'core/components/common/NotFound'),
    'NotFound',
);
const BrokerList = loaderFactory(
    () => import(/* webpackChunkName: "BrokerList" */ 'site/components/broker/BrokerList'),
    'BrokerList',
);
const AccountMaster = loaderFactory(
    () => import(/* webpackChunkName: "AccountMaster" */ 'site/components/account/AccountMaster'),
    'AccountMaster',
);
const ContactsPage = loaderFactory(
    () => import(/* webpackChunkName: "ContactsPage" */ 'site/components/contacts/ContactsPage'),
    'ContactsPage',
);
const ReviewsPage = loaderFactory(
    () => import(/* webpackChunkName: "Rewiews" */ 'site/components/reviews/Reviews'),
    'Rewiews',
);
const PrivateProductDetail = loaderFactory(
    () =>
        import(
            /* webpackChunkName: "PrivateProductDetail" */ 'site/components/product/private/PrivateProductDetailContainer'
        ),
    'PrivateProductDetail',
);
const LecturersList = loaderFactory(
    () => import(/* webpackChunkName: "LecturersList" */ 'site/components/lecturers/LecturersList'),
    'LecturersList',
);
const LecturerDetail = loaderFactory(
    () => import(/* webpackChunkName: "LecturerDetail" */ 'site/components/lecturers/LecturerDetail'),
    'LecturerDetail',
);
const ResetPassword = loaderFactory(
    () => import(/* webpackChunkName: "ResetPassword" */ 'site/components/auth/ResetPassword'),
    'ResetPassword',
);
const Page = loaderFactory(() => import(/* webpackChunkName: "Page" */ 'site/components/pages/Page'), 'Page');
const Articles = loaderFactory(
    () => import(/* webpackChunkName: "Articles" */ 'site/components/articles/Articles'),
    'Articles',
);
const ArticleDetail = loaderFactory(
    () => import(/* webpackChunkName: "ArticleDetail" */ 'site/components/articles/ArticleDetail'),
    'ArticleDetail',
);
const BondsPortfolio = loaderFactory(
    () => import(/* webpackChunkName: "BondsPortfolio" */ 'site/components/bonds-portfolio/BondsPortfolio'),
    'BondsPortfolio',
);
const ConnectTinkoff = loaderFactory(
    () => import(/* webpackChunkName: "AttachTinkoff" */ 'site/components/bonds-portfolio/tinkoff/AttachTinkoff'),
    'AttachTinkoff',
);
const OAuthLoginForm = loaderFactory(
    () => import(/* webpackChunkName: "OAuthLoginForm" */ 'site/components/auth/OAuthLoginForm'),
    'OAuthLoginForm',
);
const HighLow = loaderFactory(
    () => import(/* webpackChunkName: "HighLow" */ 'site/components/high-low/HighLow'),
    'HighLow',
);
const Evolution = loaderFactory(
    () => import(/* webpackChunkName: "Evolution" */ 'site/components/evolution/Evolution'),
    'Evolution',
);
const DividendTable = loaderFactory(
    () => import(/* webpackChunkName: "DividendTable" */ 'site/components/dividends/DividendTable'),
    'DividendTable',
);
const DividendDetail = loaderFactory(
    () => import(/* webpackChunkName: "DividendDetail" */ 'site/components/dividends/detail/DividendDetail'),
    'DividendDetail',
);
const ServicePage = loaderFactory(
    () => import(/* webpackChunkName: "ServicePage" */ 'site/components/services/ServicePage'),
    'ServicePage',
);
const ComRegion = loaderFactory(
    () => import(/* webpackChunkName: "ComRegion" */ 'site/components/communication/ComRegion'),
    'ComRegion',
);

const AttachTinkoffDisclaimer = loaderFactory(
    () =>
        import(
            /* webpackChunkName: "AttachTinkoffDisclaimer" */ 'site/components/bonds-portfolio/tinkoff/AttachTinkoffDisclaimer'
        ),
    'AttachTinkoffDisclaimer',
);
const Search = loaderFactory(
    () => import(/* webpackChunkName: "Search" */ 'site/components/search/SearchPage'),
    'Search',
);
const Heatmap = loaderFactory(
    () => import(/* webpackChunkName: "Heatmap" */ 'site/components/heatmap/Heatmap'),
    'Heatmap',
);
const Expcalendar = loaderFactory(
    () => import(/* webpackChunkName: "Expcalendar" */ 'site/components/expcalendar/Expcalendar'),
    'Expcalendar',
);
const FinancialCalculators = loaderFactory(
    () =>
        import(
            /* webpackChunkName: "FinancialCalculators" */ 'site/components/financial-calculators/FinancialCalculators'
        ),
    'FinancialCalculators',
);
const Service52WeeksPage = loaderFactory(
    () => import(/* webpackChunkName: "Service52WeeksPage" */ 'site/components/service-52-weeks/Service52WeeksPage'),
    'Service52WeeksPage',
);
const ServiceMarketMapPage = loaderFactory(
    () =>
        import(
            /* webpackChunkName: "ServiceMarketMapPage" */ 'site/components/service-market-map/ServiceMarketMapPage'
        ),
    'ServiceMarketMapPage',
);
const Roboadviser = loaderFactory(
    () => import(/* webpackChunkName: "Roboadviser" */ 'site/components/roboadviser/Roboadviser'),
    'Roboadviser',
);

const PaymentSuccess = loaderFactory(
    () => import(/* webpackChunkName: "PaymentSuccess" */ 'site/components/payments/PaymentSuccess'),
    'PaymentSuccess',
);

const routing = makeRouting({
    Main,
    ProductDetail,
    ProductDetailStart,
    CourseList,
    NotFound,
    BrokerList,
    AccountMaster,
    ContactsPage,
    ReviewsPage,
    PrivateProductDetail,
    LecturersList,
    LecturerDetail,
    ResetPassword,
    Page,
    Articles,
    ArticleDetail,
    BondsPortfolio,
    ConnectTinkoff,
    OAuthLoginForm,
    HighLow,
    Evolution,
    DividendTable,
    DividendDetail,
    ServicePage,
    AttachTinkoffDisclaimer,
    ComRegion,
    Search,
    Heatmap,
    Expcalendar,
    FinancialCalculators,
    Service52WeeksPage,
    ServiceMarketMapPage,
    Roboadviser,
    PaymentSuccess,
});

const App = () => {
    return <Master>{routing}</Master>;
};

export default App;
