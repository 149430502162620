import React from 'react';
import ReactDOM from 'react-dom';
import Snackbar, { ISnackbarProps } from './Snackbar';
import options from './options';

/* Render React component */
function renderToast(props: ISnackbarProps) {
    ReactDOM.render(<Snackbar {...props} />, document.getElementById(options.wrapperId));
}

/* Unmount React component */
export function hide() {
    ReactDOM.unmountComponentAtNode(document.getElementById(options.wrapperId));
}

/**
 * Show Animated Toast Message
 * Returns true if the toast was shown, or false if show failed due to an existing notification
 */
export function show(text: ISnackbarProps['text'], type: ISnackbarProps['type']) {
    if (!document.getElementById(options.wrapperId).hasChildNodes()) {
        // Render Component with Props.
        renderToast({ text, type });

        if (options.timeout === -1) {
            return false;
        }

        // Unmount react component after the animation finished.
        setTimeout(function() {
            hide();
        }, options.timeout + options.animationDuration);

        return true;
    }

    return false;
}

/**
 * Add to Animated Toast Message Queue
 * Display immediately if no queue
 * @param  {Number} initialRecallDelay   If the call to show fails because of an existing
 *                                       notification, how long to wait until we retry (ms)
 * @param  {Number} recallDelayIncrement Each time a successive call fails, the recall delay
 *                                       will be incremented by this (ms)
 * @return {[type]}                      [description]
 */
export function createShowQueue(initialRecallDelay = 500, recallDelayIncrement = 500) {
    // Array to hold queued messages
    const msgs: ISnackbarProps[] = [];

    // Is the showNotify function in progress - used so we can call showNotify when a
    // message is added to an empty queue.
    let isNotifying = false;
    let currentRecallDelay: number = initialRecallDelay;

    // Retrieve the next message from the queue and try to show it
    const showNotify = () => {
        // If there are no messages in the queue
        if (msgs.length === 0) {
            isNotifying = false;
            return;
        }

        isNotifying = true;

        const current = msgs.pop();

        // show will now return true if it is able to send the message,
        // or false if there is an existing message
        if (show(current.text, current.type)) {
            currentRecallDelay = initialRecallDelay;
            if (options.timeout > 0) {
                setTimeout(() => showNotify(), options.timeout + options.animationDuration);
            }
        } else {
            // If message show failed, re-add the current message to the front of the queue
            msgs.unshift(current);
            setTimeout(() => showNotify(), currentRecallDelay);
            currentRecallDelay += recallDelayIncrement;
        }
    };

    return (text: ISnackbarProps['text'], type: ISnackbarProps['type']) => {
        msgs.push({ text, type });
        if (!isNotifying) {
            showNotify();
        }
    };
}
