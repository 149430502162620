import c from 'classnames';
import { externalLogin } from 'core/api/auth';
import { IResponseError } from 'core/api/core';
import { SettingsContext } from 'core/components/settingsContext';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React, { useCallback, useContext } from 'react';
// import Facebook from '../../svg/facebook.svg';
import GooglePlus from '../../svg/google-plus.svg';
import VK from '../../svg/vk.svg';
import { useAuth } from './AuthProvider';

type ISocialsLoginProps = {
    setLoginErrors: (errors: IResponseError[]) => void;
} & WithStyles<typeof styles>;

const SocialsLogin: React.FC<ISocialsLoginProps> = ({ classes, setLoginErrors }) => {
    const { refetch, closeAuthModal, resetStore } = useAuth();
    const settings = useContext(SettingsContext);

    const handleClick = useCallback(
        (provider: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();

            return externalLogin(provider, settings.showcaseRefCode)
                .then(refetch)
                .then(() => {
                    closeAuthModal();
                    resetStore();
                })
                .catch(() => setLoginErrors([{ ErrorMessage: 'Авторизация через социальные сети не удалась' }]));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [refetch, settings, setLoginErrors],
    );

    return (
        <div className={classes.wrapper}>
            <a onClick={handleClick('Vkontakte')} className={classes.link}>
                <VK className={c(classes.icon, classes.vk)} />
            </a>
            <a onClick={handleClick('Google')} className={classes.link}>
                <GooglePlus className={c(classes.icon, classes.google)} />
            </a>
            {/* <a onClick={handleClick('Facebook')} className={classes.link}>
                <Facebook className={c(classes.icon, classes.facebook)} />
            </a> */}
        </div>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        wrapper: {
            display: 'flex',
            justifyContent: 'center',
        },
        link: {
            marginRight: 20,
            cursor: 'pointer',
            '&:last-child': {
                marginRight: 0,
            },
        },
        icon: {
            width: 50,
            height: 50,

            [theme.breakpoints.down('sm')]: {
                width: 35,
                height: 35,
            },
        },
        vk: {
            fill: '#597da3',
            '&:hover': {
                fill: '#476482',
            },
        },
        google: {
            fill: '#d94435',
            '&:hover': {
                fill: '#E26E62',
            },
        },
        // facebook: {
        //     fill: '#597da3',
        //     '&:hover': {
        //         fill: '#486abe',
        //     },
        // },
    });

export default injectSheet(styles, 'SocialsLogin')(SocialsLogin);
