import ExpandButton from 'core/components/ExpandButton';
import { CSSProperties } from 'core/style/interfaces';
import React from 'react';
import ReactSelect from 'react-select';
import { Styles, StylesConfig } from 'react-select/lib/styles';

type IOption = {
    value: string | undefined;
    label: string;
};

export type ISelectProps = {
    placeholder?: string;
    options: IOption[];
    value?: string;
    onChange: (value: string) => void;
    styles?: { [style in keyof Partial<Styles>]: CSSProperties };
    /** Красная полоска сверху при выборе */
    redLineOnSelect?: boolean;
    disabled?: boolean;
};

const IndicatorsContainer = props => (
    <ExpandButton isDownDirection={!props.selectProps.menuIsOpen} isDisabled={props.isDisabled} />
);

const findValue = (options: IOption[], value: string) =>
    value ? options.find(option => option.value === value) : null;

const Select: React.FC<ISelectProps> = props => {
    const { value, placeholder, options, onChange, styles = {}, disabled } = props;

    return (
        <ReactSelect
            isDisabled={disabled}
            noOptionsMessage={() => 'Нет вариантов'}
            styles={getSelectStyles(styles, props)}
            placeholder={placeholder}
            isClearable={false}
            isSearchable={false}
            options={options}
            value={findValue(options, value)}
            onChange={({ value }: IOption) => onChange(value)}
            components={{ IndicatorsContainer }}
        />
    );
};

const getSelectStyles = (
    customStyles: ISelectProps['styles'],
    { redLineOnSelect = true }: ISelectProps,
): StylesConfig => ({
    container: (base, state) => ({
        ...base,
        marginRight: 30,
        width: 170,
        fontFamily: 'sans-serif',

        '&:before': {
            content: '""',
            position: 'absolute',
            zIndex: state.hasValue && redLineOnSelect ? 1 : -1,
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            height: 5,
            background: '#cb0d26',
        },
        ...customStyles.container,
    }),
    control: base => ({
        ...base,
        height: '100%',
        cursor: 'pointer',
        borderRadius: 0,
        backgroundColor: 'white',
        boxShadow: null,
        borderColor: '#cccccc',
        outline: 'none',

        '&:hover, &:focus, &:active': {
            borderColor: '#cccccc',
        },
        ...customStyles.control,
    }),
    placeholder: base => ({
        ...base,
        fontWeight: 400,
        width: '100%',
        textAlign: 'center',
        fontSize: 0.9 + 'rem',
        color: '#333333',
        ...customStyles.placeholder,
    }),
    option: (base, state) => ({
        ...base,
        cursor: 'pointer',
        backgroundColor: 'white',
        color: '#333',
        fontSize: 0.9 + 'rem',
        fontWeight: state.isSelected ? 'bold' : 'normal',
        textAlign: 'center',
        borderBottom: '1px solid #cccccc',

        '&:hover, &:focus, &:active': {
            backgroundColor: '#eee',
        },

        '&:last-child': {
            borderBottom: 'none',
        },
        ...customStyles.option,
    }),
    indicatorSeparator: () => ({
        display: 'none',
        ...customStyles.indicatorSeparator,
    }),
    valueContainer: base => ({
        ...base,
        flexWrap: 'nowrap',
        overflow: 'hidden',
        ...customStyles.valueContainer,
    }),
    multiValue: base => ({
        ...base,
        flexShrink: 0,
        color: '#CC0000',
        ...customStyles.multiValue,
    }),
    singleValue: base => ({
        ...base,
        width: '100%',
        textAlign: 'center',
        fontSize: 0.9 + 'rem',
        ...customStyles.singleValue,
    }),
    menu: base => ({
        ...base,
        marginTop: 0,
        marginBottom: 0,
        borderRadius: 0,
        boxShadow: null,
        borderStyle: 'none solid solid solid',
        borderWidth: 1,
        borderColor: '#cccccc',
        zIndex: 2,
        ...customStyles.menu,
    }),
    menuList: base => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
        ...customStyles.menuList,
    }),
});

export default Select;
