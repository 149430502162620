import c from 'classnames';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import { ifBrowser } from 'core/utils';
import React from 'react';
import smoothScroll from 'smooth-scroll';

type IUpButtonState = {
    show: boolean;
};

class UpButton extends React.PureComponent<WithStyles<typeof styles>, IUpButtonState> {
    state: IUpButtonState = {
        show: false,
    };

    componentDidMount() {
        ifBrowser(window => window.addEventListener('scroll', this.scrollFunction));
    }

    componentWillUnmount() {
        ifBrowser(window => window.removeEventListener('scroll', this.scrollFunction));
    }

    scrollFunction = () => {
        const scroll = document.body.scrollTop || document.documentElement.scrollTop;
        const offset = document.body.offsetTop;

        if (scroll - offset > 20) {
            !this.state.show && this.setState({ show: true });
        } else {
            this.state.show && this.setState({ show: false });
        }
    };

    handleGotoTop() {
        const scroll = new smoothScroll();
        scroll.animateScroll(document.body);
    }

    render() {
        const { classes } = this.props;
        const { show } = this.state;

        return (
            <button onClick={this.handleGotoTop} className={c(classes.button, { [classes.show]: show })} title="Наверх">
                <span className={classes.chevron} />
            </button>
        );
    }
}

const styles = createStyles({
    button: {
        display: 'none',
        position: 'fixed',
        bottom: 20,
        left: 30,
        opacity: 0.5,
        zIndex: 99,
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid #cccccc',
        width: 50,
        height: 50,
        borderRadius: 50 + '%',
        background: 'white',
        cursor: 'pointer',
        boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.2)',

        '&:hover': {
            opacity: 1,
        },
        '&:active, &:focus': {
            border: 'none',
            outline: 'none',
        },
        '&:focus-visible': {
            outline: '2px dashed black'
        }
    },
    show: {
        display: 'flex',
    },
    chevron: {
        border: 'solid #3f4647',
        borderWidth: '0px 4px 4px 0px',
        display: 'inline-block',
        padding: 4,
        marginTop: 4,
        transform: 'rotate(-135deg)',
    },
});

export default injectSheet(styles, 'UpButton')(UpButton);
