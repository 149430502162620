import c from 'classnames';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import ArrowLeftIcon from '../svg/arrow_left.svg';

const styles = (theme: ITheme) =>
    createStyles({
        icon: {
            width: 10,
            height: 10,
            fill: '#333333',
            transform: 'rotate(-90deg)',

            [theme.breakpoints.up('lg')]: {
                width: 12,
                height: 12,
            },
        },
        iconDisabled: {
            fill: '#ccc',
        },
        iconContainer: {
            padding: '0px 10px',
        },
        iconUpDirection: {
            transform: 'rotate(90deg)',
        },
    });

export type IExpandButtonProps = {
    isDownDirection: boolean;
    isDisabled?: boolean;
};

const ExpandButton: React.StatelessComponent<IExpandButtonProps & WithStyles<typeof styles>> = ({
    classes,
    isDownDirection,
    isDisabled = false,
}) => (
    <div className={classes.iconContainer}>
        <ArrowLeftIcon
            className={c(classes.icon, {
                [classes.iconUpDirection]: !isDownDirection,
                [classes.iconDisabled]: isDisabled,
            })}
        />
    </div>
);

export default injectSheet(styles)(ExpandButton);
