import {
    Certificate,
    EventRecord,
    Material,
    ProductForm,
    ProductFormPublicTypeEnum,
    PromoCodeAdminTypeEnum,
    Seminar,
    VideoCourse,
    Webinar,
} from 'site/graphql';
import { addTimeSpanToDate, StrOrDate } from '../date';
import { numEnding } from '../string';
import {
    getFirstSeminar,
    getFirstWebinar,
    getLastSeminar,
    getLastWebinar,
    orderDates,
    orderEventRecords,
} from './orderProductForms';

export const getFirstEventRecord = <T extends Pick<EventRecord, 'date'>>(eventRecords: T[]) => {
    const sorted = orderEventRecords(eventRecords);
    return sorted[0];
};

export const getFirstDate = (dates: string[]) => {
    const sorted = orderDates(dates);
    return sorted[0];
};

export type IGetLastSeminarDateWithDurationProductForm = { seminars: Pick<Seminar, 'date' | 'hours'>[] };
export const getLastSeminarDateWithDuration = (pf: IGetLastSeminarDateWithDurationProductForm) => {
    const seminar = getLastSeminar(pf.seminars);
    return addTimeSpanToDate(seminar.date, seminar.hours);
};

export type IGetLastWebinarDateWithDurationProductForm = { webinars: Pick<Webinar, 'date' | 'hours'>[] };
export const getLastWebinarDateWithDuration = (pf: IGetLastWebinarDateWithDurationProductForm) => {
    const webinar = getLastWebinar(pf.webinars);
    return addTimeSpanToDate(webinar.date, webinar.hours);
};

export type IGetLastCourseDateWithDurationProductForm = {
    seminars: Pick<Seminar, 'date' | 'hours'>[];
    webinars: Pick<Webinar, 'date' | 'hours'>[];
};
export const getLastCourseDateWithDuration = <T extends IGetLastCourseDateWithDurationProductForm>(pf: T) => {
    const webinar = getLastWebinar(pf.webinars);
    const seminar = getLastSeminar(pf.seminars);

    // Если у акции нет вебинаров и семинаров, возвращаем дату больше тукущей, чтобы пройти фильтр filterFuturesPredicate
    if (!webinar && !seminar) {
        const now = new Date();
        return now.setDate(now.getDate() + 1);
    } else if (!webinar && seminar) {
        return addTimeSpanToDate(seminar.date, seminar.hours);
    } else if (webinar && !seminar) {
        return addTimeSpanToDate(webinar.date, webinar.hours);
    }

    const webinarWithDuration = addTimeSpanToDate(webinar.date, webinar.hours);
    const seminarWithDuration = addTimeSpanToDate(seminar.date, seminar.hours);

    return webinarWithDuration > seminarWithDuration ? webinarWithDuration : seminarWithDuration;
};

export type IGetFormSoonCauseProductForm = {
    webinars: Pick<Webinar, 'date' | 'soonCause'>[];
    seminars: Pick<Seminar, 'date' | 'soonCause'>[];
} & Pick<ProductForm, 'type'>;
export const getFormSoonCause = (productForm: IGetFormSoonCauseProductForm): string | null => {
    switch (productForm.type) {
        case 'WEBINAR':
            return getFirstWebinar(productForm.webinars).soonCause;
        case 'SEMINAR':
            return getFirstSeminar(productForm.seminars).soonCause;
        default:
            return null;
    }
};

export type IGetFormItemsCountProductForm = {
    records: Pick<EventRecord, 'id'>[];
    webinars: Pick<Webinar, 'id'>[];
    seminars: Pick<Seminar, 'id'>[];
    videoCourses: Pick<VideoCourse, 'id'>[];
    materials: Pick<Material, 'id'>[];
    certificates: Pick<Certificate, 'id'>[];
} & Pick<ProductForm, 'type'>;
/** Получить кол-во форм-итемов формы */
export const getFormItemsCount = (productForm: IGetFormItemsCountProductForm): string | null => {
    switch (productForm.type) {
        case ProductFormPublicTypeEnum.Webinar: {
            const count = productForm.webinars.length;
            return `${count} ${numEnding(count, ['занятие', 'занятия', 'занятий'])}`;
        }
        case ProductFormPublicTypeEnum.Seminar: {
            const count = productForm.seminars.length;
            return `${count} ${numEnding(count, ['занятие', 'занятия', 'занятий'])}`;
        }
        case ProductFormPublicTypeEnum.EventRecord: {
            const count = productForm.records.length;
            return `${count} ${numEnding(count, ['запись', 'записи', 'записей'])}`;
        }
        case ProductFormPublicTypeEnum.VideoCourse: {
            const count = productForm.videoCourses.length;
            return `${count} ${numEnding(count, ['занятие', 'занятия', 'занятий'])}`;
        }
        case ProductFormPublicTypeEnum.Material: {
            const count = productForm.materials.length;
            return `${count} ${numEnding(count, ['файл', 'файла', 'файлов'])}`;
        }
        case ProductFormPublicTypeEnum.Certificate: {
            const count = productForm.certificates.length;
            return `${count} ${numEnding(count, ['файл', 'файла', 'файлов'])}`;
        }
        default:
            return null;
    }
};

export type IGetFormFirstDateProductForm = {
    records: Pick<EventRecord, 'date'>[];
    webinars: Pick<Webinar, 'date'>[];
    seminars: Pick<Seminar, 'date'>[];
} & Pick<ProductForm, 'type'>;
/** Получить первую дату формы или null если ее нет */
export const getFormFirstDate = (productForm: IGetFormFirstDateProductForm): Date | null => {
    switch (productForm.type) {
        case ProductFormPublicTypeEnum.Webinar:
            return new Date(getFirstWebinar(productForm.webinars).date);
        case ProductFormPublicTypeEnum.Seminar:
            return new Date(getFirstSeminar(productForm.seminars).date);
        case ProductFormPublicTypeEnum.EventRecord: {
            const eventRecordFirstDate = getFirstEventRecord(productForm.records).date;

            return eventRecordFirstDate === null ? null : new Date(eventRecordFirstDate);
        }
        case ProductFormPublicTypeEnum.Offer: {
            if (productForm.webinars.length === 0 && productForm.seminars.length === 0) {
                return null;
            }

            const firstWebinar = getFirstWebinar(productForm.webinars);
            const firstSeminar = getFirstSeminar(productForm.seminars);

            return new Date(getFirstDate([firstWebinar && firstWebinar.date, firstSeminar && firstSeminar.date]));
        }
        default:
            return null;
    }
};

/**
 * Рассчитать цену с учетом промодка
 * @param price Цена
 * @param type Тип промокода
 * @param amount Сумма скидки
 */
export const priceWithPromoCode = (price: number, type: PromoCodeAdminTypeEnum, amount: number): number => {
    if (type === PromoCodeAdminTypeEnum.Amount) {
        return price - amount < 0 ? 0 : price - amount;
    }

    return price * (1 - amount / 100);
};

/** Типы форм продукта, которые участвуют в базовой подписке */
export const productFormSubscriptionTypes: ProductFormPublicTypeEnum[] = [
    ProductFormPublicTypeEnum.Certificate,
    ProductFormPublicTypeEnum.Material,
    ProductFormPublicTypeEnum.EventRecord,
    ProductFormPublicTypeEnum.VideoCourse,
];

/** Форма продукта доступна по подписке */
export const isProductFormSubscriptionAccess = (
    productForm: Pick<ProductForm, 'id' | 'price' | 'subscriptionAccess' | 'type'>,
) =>
    productForm.price === 0 &&
    productForm.subscriptionAccess &&
    productFormSubscriptionTypes.includes(productForm.type);

/**
 * Проверяет прошла ли дата, если даты нет считаем что не прошла
 * @param date дата
 */
export const isDatePast = (date: StrOrDate, hours: number) => {
    const converted = addTimeSpanToDate(date, hours);
    return converted ? converted <= new Date() : false;
};
