import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import ArrowLeftIcon from '../svg/arrow_left.svg';

type IExpansionPanelProps = {
    expanded: boolean;
    onClick: (event: React.MouseEvent<HTMLElement>, expanded: boolean) => void;
    summary: JSX.Element;
    details: JSX.Element;
} & WithStyles<typeof styles>;

// https://freefrontend.com/html-details-summary-css/
class ExpansionPanel extends React.PureComponent<IExpansionPanelProps> {
    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        const { onClick, expanded } = this.props;
        onClick(event, !expanded);
    };

    render() {
        const { summary, details, expanded, classes } = this.props;

        return (
            <details open={expanded} className={classes.details}>
                {/* https://github.com/philipwalton/flexbugs#flexbug-9 */}
                <summary className={classes.summary} onClick={this.handleClick}>
                    <div className={classes.summaryContainer}>
                        <div className={classes.summaryContent}>{summary}</div>
                        <ArrowLeftIcon className={classes.icon} />
                    </div>
                </summary>
                {/* Для IE условие */}
                {expanded && <>{details}</>}
            </details>
        );
    }
}

const styles = (theme: ITheme) =>
    createStyles({
        details: {
            userSelect: 'none',
            borderBottom: `1px solid ${theme.colors.lightGray}`,

            '&:focus': {
                outline: 'none',
            },
            '&[open]': {
                '& summary svg': {
                    transform: 'rotate(-90deg)',
                },
            },
        },
        summary: {
            cursor: 'pointer',
            display: 'block',

            '&:focus': {
                outline: 'none',
            },
            '&::-webkit-details-marker': {
                display: 'none',
            },
            '&::-moz-list-bullet': {
                listStyleType: 'none',
            },
        },
        summaryContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        summaryContent: {
            flex: 1,
        },
        icon: {
            height: 0.6 + 'rem',
            width: 0.6 + 'rem',
            marginRight: 0.5 + 'rem',
            fill: theme.colors.primary,
            transform: 'rotate(90deg)',
        },
    });

export default injectSheet(styles, 'ExpansionPanel')(ExpansionPanel);
