import { MyAccount } from 'site/graphql';
import { phoneRegex } from '../const';

export enum ProductFormRequiredFields {
    firstName = 1,
    lastName = 2,
    middleName = 4,
    phone = 8,
    telegramUserName = 9,
    offer = 16,
}

export const checkRequiredField = (field: ProductFormRequiredFields, requiredFields: number) =>
    (field & requiredFields) !== 0;

const checkAccountRequiredFields = (
    account: Pick<MyAccount, 'id' | 'firstName' | 'lastName' | 'middleName' | 'phones' | 'telegramUserName'>,
    requiredFields: number,
    hasProductAgreement: boolean,
    offer: string,
): boolean => {
    if (checkRequiredField(ProductFormRequiredFields.firstName, requiredFields) && !account.firstName) {
        return false;
    }

    if (checkRequiredField(ProductFormRequiredFields.lastName, requiredFields) && !account.lastName) {
        return false;
    }

    if (checkRequiredField(ProductFormRequiredFields.middleName, requiredFields) && !account.middleName) {
        return false;
    }

    if (checkRequiredField(ProductFormRequiredFields.phone, requiredFields) && !phoneRegex.test(account.phones)) {
        return false;
    }

    if (checkRequiredField(ProductFormRequiredFields.telegramUserName, requiredFields) && !account.telegramUserName) {
        return false;
    }

    if (!!offer && checkRequiredField(ProductFormRequiredFields.offer, requiredFields) && !hasProductAgreement) {
        return false;
    }

    return true;
};

export default checkAccountRequiredFields;
