import { createShowQueue } from './notify';
import { ResponseError, NotFoundError, ForbidError, BadRequestError } from 'core/api/core';

const show = createShowQueue();

type IOpenSnackbarPayload = {
    snackbarMessage?: string;
    snackbarErrors?: {
        message: string;
    }[];
};

const getErrorMessage = (error: IOpenSnackbarPayload['snackbarErrors']) => {
    if (error instanceof ResponseError) return 'Что-то пошло не так...';
    else if (error instanceof NotFoundError) return 'Элемент не найден';
    else if (error instanceof ForbidError) return 'У вас недостаточно прав';
    else if (error instanceof BadRequestError) return error.errors.map(x => x.message).join('\n');
    else if (Array.isArray(error)) return error.map(x => x.message).join('\n');
    else return 'Ошибка сохранения';
};

export const showSnackbar = ({ snackbarErrors, snackbarMessage }: IOpenSnackbarPayload) => {
    if (snackbarErrors) {
        show(getErrorMessage(snackbarErrors), 'error');
    } else {
        show(snackbarMessage || 'Сохранено', 'success');
    }
};

export { default as SnackbarContainer } from './SnackbarContainer';
