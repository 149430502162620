import { login as loginApi, restorePassword as restorePasswordApi, signup as signupApi } from 'core/api/auth';
import { IResponseError } from 'core/api/core';
import CenterLoader from 'core/components/common/CenterLoader';
import CustomCheckbox from 'core/components/CustomCheckbox';
import { useLinks } from 'core/components/linksContext';
import { SettingsContext } from 'core/components/settingsContext';
import { button, buttonAccent, field, fieldInput, fieldInputInvalid, reverseLink } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { CSSProperties, ITheme, WithStyles } from 'core/style/interfaces';
import { ifBrowser } from 'core/utils';
import React, { useCallback, useContext, useState } from 'react';
import Recaptcha from 'react-google-recaptcha';
import { phoneRegex, phoneRegexRussian } from 'site/components/const';
import { useAuth } from '../AuthProvider';
import AuthFormEmailField from './AuthFormEmailField';
import AuthFormPasswordField from './AuthFormPasswordField';
import AuthFormPhoneField from './AuthFormPhoneField';

ifBrowser(() => {
    window.recaptchaOptions = {
        lang: 'ru',
    };
});

type IAuthFormContentProps = {
    password: string;
    token: string;
    passwordError: string;
    tokenError: string;
    restoreMessage: string;
    showRememberMe: boolean;
    loginErrors: IResponseError[];
    signupErrors: IResponseError[];
    restoreErrors: IResponseError[];
    setPassword: (password: string) => void;
    setToken: (password: string) => void;
    setPasswordError: (error: string) => void;
    setTokenError: (error: string) => void;
    setRestoreMessage: (message: string) => void;
    setLoginErrors: (errors: IResponseError[]) => void;
    setSignupErrors: (errors: IResponseError[]) => void;
    setRestoreErrors: (errors: IResponseError[]) => void;
    goToLogin(): void;
    goToSignUp(): void;
} & WithStyles<typeof styles>;

const AuthFormContent: React.FC<IAuthFormContentProps> = ({
    classes,
    showRememberMe,
    setLoginErrors,
    loginErrors,
    password,
    setPasswordError,
    token,
    setTokenError,
    setPassword,
    setToken,
    setSignupErrors,
    setRestoreMessage,
    passwordError,
    setRestoreErrors,
    tokenError,
    signupErrors,
    goToLogin,
    goToSignUp,
    restoreMessage,
    restoreErrors,
}) => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [notMyComp, setNotMyComp] = useState(false);
    const [offer, setOffer] = useState(true);
    const [offerError, setOfferError] = useState('');
    const [phone, setPhone] = React.useState('+7');
    const [phoneError, setPhoneError] = React.useState('');

    const links = useLinks();
    const settings = useContext(SettingsContext);
    const { state, setState, closeAuthModal, resetStore } = useAuth();

    const validateEmail = useCallback(() => {
        const regExp =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.length === 0) {
            setEmailError('Почта не указана');
            return false;
        }

        if (!regExp.test(email.toLowerCase())) {
            setEmailError('Неверный формат почты');
            return false;
        }

        return true;
    }, [email, setEmailError]);

    const validatePhone = useCallback(() => {
        if (phone.length === 0) {
            setPhoneError('Номер телефона не указан');
            return false;
        }
        if (!phoneRegexRussian.test(phone) && !phoneRegex.test(phone)) {
            setPhoneError('Неверный формат телефона');
            return false;
        }

        return true;
    }, [phone, setPhoneError]);

    const validatePassword = useCallback(() => {
        if (password.length === 0) {
            setPasswordError('Заполните это поле');
            return false;
        }

        if (password.length < 4) {
            setPasswordError('Длина пароля должна быть не меньше 4 символов');
            return false;
        }

        return true;
    }, [password, setPasswordError]);

    const validateOffer = useCallback(() => {
        if (!offer) {
            setOfferError('Вы должны согласиться с правилами');
            return false;
        }

        return true;
    }, [offer, setOfferError]);

    const validateToken = useCallback(() => {
        if (!token) {
            setTokenError('Подтвердите, что вы не робот');
            return false;
        }

        return true;
    }, [token, setTokenError]);

    const handleChangeEmail = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            setEmail(event.currentTarget.value);
            setEmailError('');
        },
        [setEmail, setEmailError],
    );

    const handleChangePhone = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setPhone(event.currentTarget.value);
            setPhoneError('');
        },
        [setPhone, setPhoneError],
    );

    const handleChangePassword = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.currentTarget.value);
            setPasswordError('');
        },
        [setPassword, setPasswordError],
    );

    const handleChangeToken = useCallback(
        (token: string) => {
            setToken(token);
            setTokenError('');
        },
        [setToken, setTokenError],
    );

    const handleChangeOffer = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setOffer(event.currentTarget.checked);
            setOfferError(event.currentTarget.checked ? '' : 'Вы должны согласиться с правилами');
        },
        [setOffer, setOfferError],
    );

    const handleChangeTokenError = useCallback(() => {
        setToken('');
        setTokenError('Докажите, что вы не робот');
    }, [setToken, setTokenError]);

    const login = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            const emailValid = validateEmail();
            const passwordValid = validatePassword();
            // const phoneValid = validatePhone();

            if (emailValid && passwordValid) {
                setLoading(true);

                loginApi({ email, password, notMyComp })
                    .then(() => {
                        setLoading(false);
                        closeAuthModal();
                        resetStore();
                    })
                    .catch(err => {
                        setLoading(false);
                        setLoginErrors(err.errors);
                    });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [email, password, notMyComp, validateEmail, validatePassword, validatePhone],
    );

    const signup = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            const emailValid = validateEmail();
            const phoneValid = validatePhone();
            const passwordValid = validatePassword();
            const offerValid = validateOffer();
            const tokenValid = validateToken();

            if (emailValid && phoneValid && passwordValid && offerValid && tokenValid) {
                setLoading(true);

                signupApi({
                    email,
                    phone,
                    password,
                    token,
                    refCode: settings.showcaseRefCode,
                    contentProviderId: settings.contentProviderId,
                })
                    .then(() => {
                        setLoading(false);
                        setToken('');
                        setState('success');
                        resetStore();
                    })
                    .catch(err => {
                        setLoading(false);
                        window.grecaptcha.reset();
                        setSignupErrors(err.errors);
                    });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            email,
            phone,
            password,
            token,
            settings,
            validateEmail,
            validatePassword,
            validateOffer,
            validateToken,
            validatePhone,
        ],
    );

    const restorePassword = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            const emailValid = validateEmail();

            if (emailValid) {
                setLoading(true);

                restorePasswordApi(email)
                    .then(() => {
                        setRestoreMessage(`Письмо с ссылкой для восстановления успешно отправлено на ${email}`);
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        setRestoreErrors(err.errors);
                    });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [email, validateEmail],
    );

    switch (state) {
        case 'login':
            return (
                <form className={classes.content} onSubmit={login}>
                    <AuthFormEmailField
                        email={email}
                        emailError={emailError}
                        onChange={handleChangeEmail}
                        validateEmail={validateEmail}
                        classes={classes}
                    />
                    <AuthFormPasswordField
                        classes={classes}
                        onChange={handleChangePassword}
                        password={password}
                        passwordError={passwordError}
                        state={state}
                        validatePassword={validatePassword}
                    />
                    <div className={classes.textCenter}>
                        {showRememberMe && (
                            <CustomCheckbox onChange={setNotMyComp} checked={notMyComp} label="Чужой компьютер" />
                        )}

                        <span className={classes.linkDark} onMouseDown={() => setState('restore')}>
                            Забыли пароль?
                        </span>
                    </div>

                    {loading ? (
                        <CenterLoader absolute />
                    ) : (
                        <input type="submit" value="Войти" className={classes.submit} />
                    )}

                    {loginErrors.map(error => (
                        <span className={classes.error} key={error.ErrorMessage}>
                            {error.ErrorMessage}
                        </span>
                    ))}
                </form>
            );
        case 'signup':
            return (
                <form className={classes.content} onSubmit={signup}>
                    <AuthFormEmailField
                        email={email}
                        emailError={emailError}
                        onChange={handleChangeEmail}
                        validateEmail={validateEmail}
                        classes={classes}
                    />
                    <AuthFormPhoneField
                        classes={classes}
                        onChange={handleChangePhone}
                        phone={phone}
                        validatePhone={validatePhone}
                        phoneError={phoneError}
                    />
                    <AuthFormPasswordField
                        classes={classes}
                        onChange={handleChangePassword}
                        password={password}
                        passwordError={passwordError}
                        state={state}
                        validatePassword={validatePassword}
                    />
                    {
                        <label className={classes.field}>
                            <Recaptcha
                                className={classes.captcha}
                                sitekey="6Lfk1jYUAAAAAD41PmLwd9U9hJOXQOVt_--BNWj-"
                                onChange={handleChangeToken}
                                onExpired={handleChangeTokenError}
                            />
                            <span className={classes.error}>{tokenError}</span>
                        </label>
                    }
                    <label className={classes.field}>
                        <input
                            type="checkbox"
                            name="offer"
                            style={{ width: 25, height: 25, position: 'absolute', margin: '0 15px' }}
                            checked={offer}
                            onChange={handleChangeOffer}
                        />
                        <div style={{ paddingLeft: 55, paddingRight: 10, fontSize: 16, lineHeight: 1 }}>
                            Я прочитал{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={links.userAgreement.url()}
                                className={classes.link}
                            >
                                пользовательское соглашение
                            </a>{' '}
                            и{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={links.personalAgreement.url()}
                                className={classes.link}
                            >
                                согласие на обработку персональных данных
                            </a>{' '}
                            и принимаю все условия
                        </div>
                        <span className={classes.error}>{offerError}</span>
                    </label>

                    {loading ? (
                        <CenterLoader absolute />
                    ) : (
                        <input type="submit" value="Зарегистрироваться" className={classes.submit} />
                    )}

                    {signupErrors.map(error => (
                        <span className={classes.error} key={error.ErrorMessage}>
                            {error.ErrorMessage + ' '}
                            {error.ErrorMessage.indexOf('Аккаунт уже существует') > -1 && (
                                <span className={classes.link} onMouseDown={goToLogin}>
                                    Войти
                                </span>
                            )}
                        </span>
                    ))}
                </form>
            );
        case 'restore':
            return (
                <form className={classes.content} onSubmit={restorePassword}>
                    Мы отправим ссылку для восстановления пароля на ваш email
                    <AuthFormEmailField
                        email={email}
                        emailError={emailError}
                        onChange={handleChangeEmail}
                        validateEmail={validateEmail}
                        classes={classes}
                    />
                    <span className={classes.successText}>{restoreMessage}</span>
                    {loading ? (
                        <CenterLoader absolute />
                    ) : (
                        <input type="submit" value="Восстановить пароль" className={classes.submit} />
                    )}
                    {restoreErrors.map(error => (
                        <span className={classes.error} key={error.ErrorMessage}>
                            {error.ErrorMessage + ' '}
                            {error.ErrorMessage.indexOf('Аккаунт не зарегистрирован') > -1 && (
                                <span className={classes.link} onMouseDown={goToSignUp}>
                                    Регистрация
                                </span>
                            )}
                        </span>
                    ))}
                </form>
            );
        case 'success':
            return (
                <div className={classes.successTab}>
                    <div className={classes.successIcon} />
                    <h3>Поздравляем!</h3>
                    <p>Вы успешно зарегистрированы на сайте</p>
                    <p>
                        <strong>
                            В письме вы найдете промокод
                            <br />
                            на скидку 10% на любой курс!
                            <br />
                            Торопитесь, промокод действует
                            <br />
                            всего 1 месяц
                        </strong>
                    </p>
                </div>
            );
        // skip default
    }
};

const link = (theme: ITheme): CSSProperties => ({
    ...reverseLink(theme.colors.link),
    '&:hover': {
        color: theme.colors.accentMenu,
    },
});

export const textCenter = (theme: ITheme): CSSProperties => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 16,
    marginBottom: '1rem',
    color: theme.colors.contrastBg,
    [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        flexGrow: 1,
    },
});

const styles = (theme: ITheme) =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            padding: '1.25rem 1.75rem',
            justifyContent: 'center',
            flexGrow: 1,
            fontSize: 16,
            color: theme.colors.contrastBg,
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
            },
        },
        successIcon: {
            background: `url(${require('./finger.png')})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundOrigin: 'content-box',
            boxSizing: 'border-box',
            padding: 25,
            width: 200,
            height: 200,
        },
        successTab: {
            ...textCenter(theme),
            lineHeight: 1,
            backgroundColor: 'white',
            color: theme.colors.contrastBg,
            marginBottom: 0,
            '& p': {
                marginBottom: '19px',
            },
        },
        successText: {
            color: 'green',
            fontSize: 14,
            textAlign: 'center',
            marginBottom: 1 + 'rem',
        },
        submit: {
            ...button(theme),
            ...buttonAccent(theme),

            [theme.breakpoints.down('sm')]: {
                fontSize: 16,
                padding: 10,
            },
        },
        link: {
            ...link(theme),
        },
        linkDark: {
            ...link(theme),
            ...reverseLink(theme.colors.contrastBg),
        },
        field: {
            ...field,
            marginBottom: '1rem',
            color: theme.colors.contrastBg,
        },
        fieldInput: {
            ...fieldInput,
            fontSize: 1 + 'rem',

            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
                height: 20,
            },
        },
        fieldInputInvalid: {
            ...fieldInputInvalid,
        },
        error: {
            color: theme.colors.accentLight,
            fontSize: 14,
            display: 'block',
        },
        textCenter: {
            ...textCenter(theme),
        },
        rowField: {
            ...field,
            marginBottom: '1rem',
            color: theme.colors.contrastBg,
            flexDirection: 'row',
            alignItems: 'center',

            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
            },
        },
        captcha: {
            alignSelf: 'center',

            [theme.breakpoints.down(360)]: {
                transform: 'scale(0.88)',
            },
        },
    });

export default injectSheet(styles, 'AuthFormContent')(AuthFormContent);
