import c from 'classnames';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import React from 'react';
import CircularProgress from './CircularProgress';

type ICenterLoaderProps = {
    absolute?: boolean;
    disableMinHeight?: boolean;
} & WithStyles<typeof styles>;

const CenterLoader: React.StatelessComponent<ICenterLoaderProps> = ({
    classes,
    absolute,
    disableMinHeight = false,
}) => (
    <div
        className={c(classes.root, {
            [classes.rootDisableMinHeight]: disableMinHeight,
            [classes.absoluteRoot]: absolute,
        })}
    >
        <CircularProgress className={classes.progress} />
    </div>
);

const styles = createStyles({
    root: {
        minHeight: '50vh',
        display: 'flex',
        width: 100 + '%',
        justifyContent: 'center',
    },
    rootDisableMinHeight: {
        minHeight: 'auto',
    },
    absoluteRoot: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        minHeight: 0,
    },
    progress: {
        alignSelf: 'center',
        color: '#CC0000',
    },
});

export default injectSheet(styles)(CenterLoader);
