/* eslint-disable no-console */
import hash from '@emotion/hash';

function safePrefix(classNamePrefix) {
    const prefix = String(classNamePrefix);
    if (process.env.NODE_ENV !== 'production') {
        if (prefix.length >= 256) {
            console.error(`Material-UI: the class name prefix is too long: ${prefix}.`);
        }
    }

    return prefix;
}

/**
 * IE игнорирует неалфавитные символы при a.localeCompare(b)
 */
function sortString(a: string, b: string) {
    const lowerA = a.toLowerCase();
    const lowerB = b.toLowerCase();

    if (lowerA < lowerB) return -1;
    if (lowerA > lowerB) return 1;
    return 0;
}

/**
 * Fork https://github.com/mui-org/material-ui/blob/master/packages/material-ui-styles/src/createGenerateClassName/createGenerateClassNameHash.js
 *
 * Почему не использовать createGenerateClassName.js?
 * Применяется идея с обычным счетчиком ruleCounter += 1, из-за чего classname SSR могут отличаться от клиентских.
 * Например если подярдок классов будет отличаться или если на SSR рендерится меньше компонентов, чем на
 * клиента, в нашем проекте это чатый кейс для ifBrowser().
 *
 * Почему не использовать createGenerateClassNameHash.js?
 * Применяется идея с хэшированием объекта стилей hash(`${themeHash}${rule.key}${JSON.stringify(raw)}`).
 * Но JS не гарантирует порядок свойств в объекте при JSON.stringify(obj).
 * С-но возникла проблема в Safari, когда с SSR приходит
 * одно название classname, а на клиенте высчитывается другое.
 *
 * Идея данной реализации.
 * Берем идею от createGenerateClassNameHash, но перед хэшированием изменяем объект стилей raw.
 * Оставляем неизменными поля с типами number и string, всем остальным
 * проставляем значение 'object'. Сортируем ключи полученного объекта.
 * Отправляем полученный объект в JSON.stringify(raw).
 */
export default function generateClassName() {
    let ruleCounter = 0;

    return (rule, styleSheet): string => {
        // Считаем кол-во правил для идентификации учетчки памяти
        if (process.env.NODE_ENV !== 'production') {
            ruleCounter += 1;

            if (ruleCounter >= 1e10) {
                console.warn(
                    [
                        'Material-UI: you might have a memory leak.',
                        'The ruleCounter is not supposed to grow that much.',
                    ].join(''),
                );
            }
        }

        const raw = styleSheet.rules.raw[rule.key];

        // Сортируем ключи
        const sortedKeys = raw ? Object.keys(raw).sort(sortString) : [];

        // Собираем строку со стилями
        const processedRaw = sortedKeys.reduce((result, key) => {
            const value = typeof raw[key] === 'string' || typeof raw[key] === 'number' ? raw[key] : 'object';
            return result + key + value;
        }, '');

        // Рассчитываем хэш
        const suffix = hash(`${rule.key}${processedRaw}`);

        if (process.env.NODE_ENV === 'production') {
            return 'jss' + suffix;
        }

        // Help with debuggability.
        if (styleSheet.options.classNamePrefix) {
            return `jss-${safePrefix(styleSheet.options.classNamePrefix)}-${rule.key}-${suffix}`;
        }

        return `jss-${rule.key}-${suffix}`;
    };
}
