import { Breakpoint, BreakpointsOptions, Breakpoints } from 'core/style/interfaces';
import { ifBrowser } from 'core/utils';

// From: https://github.com/mui-org/material-ui/blob/89687f38cae750650555772ba4d821c9084d8dfc/packages/material-ui/src/styles/createBreakpoints.js
// Sorted ASC by size. That's important.
// It can't be configured as it's used statically for propTypes.
export const keys: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl'];
const DEFAULT_BREAKPOINTS: Record<Breakpoint, number> = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};

export const createRuntimeBreakpoints = (breakpoints = DEFAULT_BREAKPOINTS) => () => {
    return {
        smUp: ifBrowser() && window.innerWidth >= breakpoints.sm,
        mdUp: ifBrowser() && window.innerWidth >= breakpoints.md,
        lgUp: ifBrowser() && window.innerWidth >= breakpoints.lg,
        xlUp: ifBrowser() && window.innerWidth >= breakpoints.xl,
        smDown: ifBrowser() && window.innerWidth < breakpoints.sm,
        mdDown: ifBrowser() && window.innerWidth < breakpoints.md,
        lgDown: ifBrowser() && window.innerWidth < breakpoints.lg,
        xlDown: ifBrowser() && window.innerWidth < breakpoints.xl,
    };
};

// Keep in mind that @media is inclusive by the CSS specification.
export default function createBreakpoints(breakpoints: BreakpointsOptions): Breakpoints {
    const {
        // The breakpoint **start** at this value.
        // For instance with the first breakpoint xs: [xs, sm[.
        values = DEFAULT_BREAKPOINTS,
        unit = 'px',
        step = 5,
        ...other
    } = breakpoints;

    function up(key) {
        const value = typeof values[key] === 'number' ? values[key] : key;
        return `@media (min-width:${value}${unit})`;
    }

    function down(key) {
        const endIndex = keys.indexOf(key) + 1;
        const upperbound = values[keys[endIndex]];

        if (endIndex === keys.length) {
            // xl down applies to all sizes
            return up('xs');
        }

        const value = typeof upperbound === 'number' && endIndex > 0 ? upperbound : key;
        return `@media (max-width:${value - step / 100}${unit})`;
    }

    function between(start, end) {
        const endIndex = keys.indexOf(end) + 1;

        if (endIndex === keys.length) {
            return up(start);
        }

        return (
            `@media (min-width:${values[start]}${unit}) and ` +
            `(max-width:${values[keys[endIndex]] - step / 100}${unit})`
        );
    }

    function only(key) {
        return between(key, key);
    }

    function width(key) {
        return values[key];
    }

    return {
        keys,
        values,
        up,
        down,
        between,
        only,
        width,
        ...other,
    };
}
