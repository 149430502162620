import React, { useState } from 'react';
import { GetMyAccountQuery } from 'site/graphql';
import useGetMyAccount from './hooks/useGetMyAccount';

export type IAuthFormState = 'login' | 'signup' | 'restore' | 'success';
export type IAuthContext = {
    /** Авторизация пользователя */
    isLoggedIn: boolean;
    /** Текущий аккаунт пользователь */
    myAccount: GetMyAccountQuery['myAccount'];
    /** Загрузка аккаунта пользователя */
    loading: boolean;
    /** Выход */
    logout: () => void;
    /** Перезапрос аккаунта пользователя */
    refetch: () => void;
    /** Перезапрос всех активных query */
    resetStore: () => void;
    /** Открыто ли модальное окно авторизации */
    openModal: boolean;
    /** Открытие модального окна авторизации */
    openAuthModal: (state: IAuthFormState) => void;
    /** Закрытие модального окна авторизации */
    closeAuthModal: () => void;
    /** Состояние AuthForm */
    state: IAuthFormState;
    /** Смена состояния AuthForm */
    setState: React.Dispatch<React.SetStateAction<IAuthFormState>>;
    /** Id формы продукта для покупки (если не null - открыто модальное окно покупки) */
    payProductFormId: number | null;
    /** Управление id формы продукта для покупки и открытием модального окна покупки */
    setPayProductFormId: React.Dispatch<React.SetStateAction<number>>;
    /** Есть ли действующая базовая подписка */
    hasSubscription: boolean;
};

export const AuthContext = React.createContext<IAuthContext>({
    isLoggedIn: false,
    myAccount: null,
    loading: false,
    logout: () => null,
    refetch: () => null,
    resetStore: () => null,
    openModal: false,
    openAuthModal: () => null,
    closeAuthModal: () => null,
    state: 'login',
    setState: () => null,
    payProductFormId: null,
    setPayProductFormId: () => null,
    hasSubscription: false,
});

const AuthProvider: React.FC = ({ children }) => {
    const [myAccount, loading, refetch, logout, resetStore] = useGetMyAccount();
    const [payProductFormId, setPayProductFormId] = useState<number | null>(null);

    const [openModal, setOpenModal] = useState(false);
    const [state, setState] = useState<IAuthFormState>('login');

    const subscription = myAccount && myAccount.subscription;
    const hasSubscription = !!subscription && !!subscription.until;

    const openAuthModal = (state: IAuthFormState) => {
        setState(state);
        setOpenModal(true);
    };
    const closeAuthModal = () => {
        setOpenModal(false);
    };

    return (
        <AuthContext.Provider
            value={{
                myAccount,
                logout,
                refetch,
                resetStore,
                loading,
                openModal,
                openAuthModal,
                closeAuthModal,
                isLoggedIn: !!myAccount,
                state,
                setState,
                payProductFormId,
                setPayProductFormId,
                hasSubscription,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
