import React, { useState } from 'react';
import { useAuth } from 'site/components/auth/AuthProvider';

export type ITinkoffContext = {
    isPinModalOpen: boolean;
    setIsPinModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    next: () => void;
    setNext: React.Dispatch<React.SetStateAction<() => void>>;
    isPlaceMarketModalOpen: boolean;
    setIsPlaceMarketModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    ticker: string;
    setTicker: React.Dispatch<React.SetStateAction<string>>;
    amount: number;
    setAmount: React.Dispatch<React.SetStateAction<number>>;
    portfolioNominal: number;
    setPortfolioNominal: React.Dispatch<React.SetStateAction<number>>;
    hasTinkoffInvest: boolean;
};

const TinkoffContext = React.createContext<ITinkoffContext>({
    isPinModalOpen: false,
    setIsPinModalOpen: () => null,
    next: () => null,
    setNext: () => null,
    isPlaceMarketModalOpen: false,
    setIsPlaceMarketModalOpen: () => null,
    ticker: '',
    setTicker: () => null,
    amount: 0,
    setAmount: () => null,
    portfolioNominal: 0,
    setPortfolioNominal: () => null,
    hasTinkoffInvest: false,
});

const useTinkoff = () => React.useContext(TinkoffContext);

const TinkoffProvider: React.FC = ({ children }) => {
    const [isPinModalOpen, setIsPinModalOpen] = useState(false);
    const [next, setNext] = useState<() => void>(() => null);
    const [isPlaceMarketModalOpen, setIsPlaceMarketModalOpen] = useState(false);
    const [ticker, setTicker] = useState('');
    const [amount, setAmount] = useState(0);
    const [portfolioNominal, setPortfolioNominal] = useState(0);

    const { myAccount } = useAuth();
    const hasTinkoffInvest = myAccount && myAccount.hasTinkoffInvest;

    return (
        <TinkoffContext.Provider
            value={{
                isPinModalOpen,
                setIsPinModalOpen,
                next,
                setNext,
                isPlaceMarketModalOpen,
                setIsPlaceMarketModalOpen,
                ticker,
                setTicker,
                amount,
                setAmount,
                hasTinkoffInvest,
                portfolioNominal,
                setPortfolioNominal,
            }}
        >
            {children}
        </TinkoffContext.Provider>
    );
};

export { TinkoffProvider, useTinkoff };
