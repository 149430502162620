/* eslint-disable no-console */
function trackMetricks() {
    return typeof window !== 'undefined' && TRACK_METRICKS;
}

// reject промиса, если нет ответа в течении timeout
const promisifyTrackExec = (fn, timeout = 2000) =>
    new Promise((resolve, reject) => {
        try {
            fn(resolve, reject);
        } catch (error) {
            console.error(error);
            resolve();
        }
        setTimeout(() => resolve(), timeout);
    });

// Яндекс.Метрика
function trackYandexMetrika(target: string, param = null): Promise<any> {
    if (!trackMetricks()) return Promise.resolve();
    if (typeof window.ym === 'undefined') return Promise.resolve();

    return promisifyTrackExec(resolve => {
        window.ym(37774220, 'reachGoal', target, param, () => resolve());
    }, 2000);
}

// Гугл аналитика
function trackGoogleAnalytics(target: string, param = null): Promise<any> {
    if (!trackMetricks()) return Promise.resolve();

    // новый Google Analytics gtag.js
    if (typeof window.gtag !== 'undefined') {
        return promisifyTrackExec(resolve => {
            window.gtag('event', `${target}`, {
                event_action: 'click',
                event_category: target,
                event_callback: () => {
                    resolve();
                },
                event_label: param,
            });
        }, 2000);
    }

    // старый Google Analytics analytics.js
    if (typeof window.ga === 'undefined') {
        return Promise.resolve();
    }

    return promisifyTrackExec(resolve => {
        window.ga('send', {
            eventAction: 'click',
            hitType: 'event',
            eventCategory: target,
            eventLabel: param,
            hitCallback: () => resolve(),
        });
    }, 2000);
}

// Методы ниже track* должны быть обернуты в try-catch, т.к. ошибки данного метода должны быть проигнорированы

/**
 * Цель на оплату с выбранным способом
 * @description Кнопка "Оплатить ...Р" в модальном окне оплаты
 */
export function trackClickPayButton(method) {
    try {
        return Promise.all([trackYandexMetrika('click-pay', { method }), trackGoogleAnalytics('click-pay', method)]);
    } catch (error) {
        console.error('Ошибка отслеживания цели trackClickPayButton', error);
    }
}

/**
 * Цель на создания заказа на форму
 * @description Кнопки "Купить" и "Присоединиться" на плитке формы
 */
export function trackJoinSuccess() {
    try {
        return Promise.all([trackYandexMetrika('join-to-course'), trackGoogleAnalytics('join-to-course')]);
    } catch (error) {
        console.error('Ошибка отслеживания цели trackJoinSuccess', error);
    }
}

/**
 * Цель на нажатие кнопки "Отмена"
 */
export function trackClickCancelButton() {
    try {
        return Promise.all([trackYandexMetrika('click-cancel'), trackGoogleAnalytics('click-cancel')]);
    } catch (error) {
        console.error('Ошибка отслеживания цели trackClickCancelButton', error);
    }
}

/**
 * Цель на нажатие кнопки "Подписаться на рассылку Вконтакте"
 */
export function trackSubscribeVkButton() {
    try {
        return Promise.all([trackYandexMetrika('subscribe-vk'), trackGoogleAnalytics('subscribe-vk')]);
    } catch (error) {
        console.error('Ошибка отслеживания цели trackSubscribeVkButton', error);
    }
}

// Ниже расположены методы Расширенной электронной торговли (Enhanced Ecommerce).
// Способ отправки данных через dataLayer одинаковый как для Яндекс.Метрики, так и для Гугл Аналитики
// Трекинг события покупки или присоединения к бесплатной форме (purchase) происходит на сервере

type ITrackingProduct = {
    /** id продукта */
    id: number;
    /** Название продукта */
    name?: string;
    /** Цена формы продукта */
    price?: number;
    /** Тип формы продукта (вебинар, материал...) */
    variant?: string;
    /** Количество форм продукта */
    quantity?: number;
    /** Теги продукта */
    category?: string[];
    /** Контент-провайдер */
    brand?: string;
};

/**
 * Преобразование продукта к формату e-commerce
 * @param product Информация о продукте для трекинга
 * @returns
 */
const prepareEcommerceProduct = (product: ITrackingProduct) => {
    return {
        ...product,
        id: product.id.toString(),
        // Выбор 1 любого тега продукта
        category: product.category && product.category.length !== 0 ? product.category[0] : '',
    };
};

/**
 * Треккинг показов деталок продуктов
 */
export function trackDetail(product: ITrackingProduct): Promise<any> {
    if (!trackMetricks()) return Promise.resolve();

    try {
        return promisifyTrackExec(resolve => {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                ecommerce: {
                    detail: {
                        products: [prepareEcommerceProduct(product)],
                    },
                },
                event: 'eec.detail',
                eventCallback: () => resolve(),
                eventTimeout: 2000,
            });
        }, 2000);
    } catch (error) {
        console.error('Ошибка отслеживания цели detail e-commerce', error);
    }
}

/**
 * Треккинг присоединения к платной форме продукта без оплаты (аналог добавления в корзину)
 */
export function trackAdd(product: ITrackingProduct): Promise<any> {
    if (!trackMetricks()) return Promise.resolve();

    try {
        return promisifyTrackExec(resolve => {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                ecommerce: {
                    add: {
                        products: [prepareEcommerceProduct(product)],
                    },
                },
                event: 'eec.add',
                eventCallback: () => resolve(),
                eventTimeout: 2000,
            });
        }, 2000);
    } catch (error) {
        console.error('Ошибка отслеживания цели add e-commerce', error);
    }
}

/**
 * Треккинг отмены присоединения к платной форме (аналог удаления из корзины)
 */
export function trackRemove(product: ITrackingProduct): Promise<any> {
    if (!trackMetricks()) return Promise.resolve();

    try {
        return promisifyTrackExec(resolve => {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                ecommerce: {
                    remove: {
                        products: [prepareEcommerceProduct(product)],
                    },
                },
                event: 'eec.remove',
                eventCallback: () => resolve(),
                eventTimeout: 2000,
            });
        }, 2000);
    } catch (error) {
        console.error('Ошибка отслеживания цели remove e-commerce', error);
    }
}
