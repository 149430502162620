import c from 'classnames';
import CenterLoader from 'core/components/common/CenterLoader';
import ExpansionPanel from 'core/components/ExpansionPanel';
import { LinksConsumer } from 'core/components/linksContext';
import { DEFAULT_SOON_CAUSE } from 'core/const';
import { button, buttonAccent, field, ieFix, reverseLink, title } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { formatToLocalDayMonth, isImpossibleDate, numEnding, priceToString } from 'core/utils';
import { IApolloError } from 'core/utils/apollo';
import { getFormFirstDate, priceWithPromoCode } from 'core/utils/product-forms/productForms';
import React from 'react';
import { GetPayProductFormQuery, InviteLink, PaymentPublicMethodEnum, PromoCode } from 'site/graphql';
import QuestionIcon from '../../svg/question-mark.svg';
import SuccessIcon from '../../svg/success.svg';
import { IExpandedType } from './PaymentFormContainer';
import PromoCodeExpansionPanel from './PromoCodeExpansionPanel';

const pointsString = (points: number) => `${points} ${numEnding(points, ['балл', 'балла', 'баллов'])}`;

type IPaymentFormPresentProps = {
    loading: boolean;
    expanded: IExpandedType | boolean;
    method: PaymentPublicMethodEnum;
    refPoints: number;
    productForm: GetPayProductFormQuery['productForm'];
    promoCode: PromoCode;
    isFree: boolean;
    promoCodeValue: string;
    promoCodeErrorMessage: string;
    createPaymentErrors: IApolloError[];
    createOrderErrors: IApolloError[];
    closeModal(): void;
    createPayment(): void;
    onExpandChange: (panel: string) => (event, expanded) => void;
    onMethodChange: (event) => void;
    onPromoCodeValueChange: (e) => void;
    onPromoCodeSubmit: (e) => void;
    inviteLink?: InviteLink;
} & WithStyles<typeof styles>;

class PaymentFormPresent extends React.Component<IPaymentFormPresentProps> {
    renderExpand(
        type: IExpandedType,
        summary: string,
        methods: Array<{ type: PaymentPublicMethodEnum; title: string }>,
    ) {
        const { expanded, onExpandChange, classes, method, onMethodChange } = this.props;

        return (
            <ExpansionPanel
                expanded={expanded === type}
                onClick={onExpandChange(type)}
                summary={<div className={classes.summary}>{summary}</div>}
                details={
                    <React.Fragment>
                        {methods.map(m => (
                            <label className={classes.field} key={m.type}>
                                <input
                                    type="radio"
                                    name={m.type}
                                    className={classes.checkbox}
                                    checked={method === m.type}
                                    onChange={onMethodChange}
                                />
                                {m.title}
                            </label>
                        ))}
                    </React.Fragment>
                }
            />
        );
    }

    renderRefPointsExpand() {
        const { expanded, onExpandChange, classes, method, onMethodChange, refPoints, productForm } = this.props;

        return (
            <ExpansionPanel
                expanded={expanded === 'POINTS'}
                onClick={onExpandChange('POINTS')}
                summary={<div className={classes.summary}>Оплата баллами</div>}
                details={
                    <div className={classes.pointsDetails}>
                        <LinksConsumer>
                            {links => (
                                <span>
                                    Ваш баланс: {pointsString(refPoints)}
                                    <a
                                        href={`${links.account.url()}referral`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <QuestionIcon style={{ marginLeft: 5, width: 15, height: 15 }} />
                                    </a>
                                </span>
                            )}
                        </LinksConsumer>
                        <br />
                        {refPoints < productForm.price && (
                            <span className={classes.error}>
                                Для оплаты не хватает {pointsString(productForm.price - refPoints)}
                            </span>
                        )}
                        <label className={classes.field}>
                            <input
                                type="radio"
                                name={'POINTS'}
                                className={classes.checkbox}
                                checked={method === 'POINTS'}
                                onChange={onMethodChange}
                                disabled={refPoints < productForm.price}
                            />
                            Оплатить баллами
                        </label>
                        Внимание! Возможна оплата только 100% суммы <br />1 балл = 1 рубль
                    </div>
                }
            />
        );
    }

    renderContent() {
        const {
            productForm,
            classes,
            promoCode,
            closeModal,
            isFree,
            createPaymentErrors,
            createPayment,
            promoCodeValue,
            onPromoCodeValueChange,
            promoCodeErrorMessage,
            onPromoCodeSubmit,
            inviteLink,
        } = this.props;
        const name = productForm.product ? productForm.product.name : productForm.title;
        const promoCodeDiscount = promoCode && priceWithPromoCode(productForm.price, promoCode.type, promoCode.amount);
        const startDate = getFormFirstDate(productForm);

        if (productForm.paymentStatus === 'ALREADY' || isFree === true) {
            return (
                <LinksConsumer>
                    {links => (
                        <div className={classes.textCenter}>
                            <SuccessIcon className={classes.successIcon} />
                            <h4>Поздравляем, вы успешно присоединились к курсу {name}</h4>
                            {inviteLink && (
                                <a href={inviteLink.link} className={classes.telegram}>
                                    Перейти в telegram чат
                                </a>
                            )}
                            {productForm.product ? (
                                <links.accountProductDetail.Link
                                    to={links.accountProductDetail.url(productForm.product.id)}
                                    onClick={closeModal}
                                >
                                    Смотреть в личном кабинете
                                </links.accountProductDetail.Link>
                            ) : (
                                <links.accountCourses.Link to={links.accountCourses.url()} onClick={closeModal}>
                                    Смотреть в личном кабинете
                                </links.accountCourses.Link>
                            )}
                            <span onClick={closeModal} className={classes.link}>
                                Продолжить выбирать курсы
                            </span>
                        </div>
                    )}
                </LinksConsumer>
            );
        }

        return (
            <React.Fragment>
                <h2 className={classes.title}>Оплата</h2>
                <h3 className={classes.description}>
                    <div>{productForm.title}</div>
                    <div>{name}</div>
                    {startDate && (
                        <div>
                            {isImpossibleDate(startDate) ? DEFAULT_SOON_CAUSE : `С ${formatToLocalDayMonth(startDate)}`}
                        </div>
                    )}
                </h3>

                {this.renderExpand('CARD', 'Банковские карты', [
                    { type: PaymentPublicMethodEnum.BankCard, title: 'С произвольной банковской карты' },
                ])}
                {this.renderExpand('WALLET', 'Интернет-платежи', [
                    { type: PaymentPublicMethodEnum.YooMoney, title: 'ЮMoney' },
                    { type: PaymentPublicMethodEnum.WebMoney, title: 'WebMoney' },
                    { type: PaymentPublicMethodEnum.SberBank, title: 'SberPay' },
                ])}
                {this.renderExpand('CASH', 'Наличные', [
                    { type: PaymentPublicMethodEnum.Cash, title: 'Наличными через терминалы' },
                ])}
                {this.renderRefPointsExpand()}
                {this.renderExpand('INSTALLMENTS', 'Оплата по частям', [
                    { type: PaymentPublicMethodEnum.Installments, title: 'Оплата по частям' },
                ])}

                {createPaymentErrors && createPaymentErrors.length
                    ? createPaymentErrors.map(error => (
                          <span className={classes.error} key={error.message}>
                              {error.message}
                          </span>
                      ))
                    : null}

                <div className={classes.submitWrapper}>
                    <button className={classes.submit} onClick={createPayment}>
                        Оплатить{' '}
                        {promoCodeDiscount || promoCodeDiscount === 0 ? (
                            <span>
                                {priceToString(promoCodeDiscount)} ({' '}
                                <span style={{ textDecoration: 'line-through' }}>
                                    {priceToString(productForm.price)}
                                </span>{' '}
                                )
                            </span>
                        ) : (
                            priceToString(productForm.price)
                        )}
                    </button>
                </div>

                <PromoCodeExpansionPanel
                    value={promoCodeValue}
                    onChange={onPromoCodeValueChange}
                    errorMessage={promoCodeErrorMessage}
                    onSubmit={onPromoCodeSubmit}
                    wrapperStyles={{ marginTop: 16 }}
                />

                <div className={classes.disclaimer}>
                    Нажимая кнопку &quot;Оплатить&quot;, вы соглашаетесь с условием{' '}
                    <b>
                        <a target="_blank" rel="noopener noreferrer" href={productForm.product.contentProvider.offer}>
                            оферты
                        </a>
                    </b>
                </div>
            </React.Fragment>
        );
    }

    render() {
        const { classes, createOrderErrors, createPaymentErrors, loading } = this.props;

        // Загрузка
        if (loading) {
            return (
                <div className={classes.wrapper}>
                    <CenterLoader />
                </div>
            );
        }

        // Ошибка при создании заказа или платежа
        if (
            (createOrderErrors && createOrderErrors.length > 0) ||
            (createPaymentErrors && createPaymentErrors.length > 0)
        ) {
            // eslint-disable-next-line no-console
            console.error('Ошибка создания заказа/платежа: ', createOrderErrors, createPaymentErrors);

            return (
                <div className={c(classes.wrapper, classes.textCenter)}>
                    Ошибка! Обновите страницу и попробуйте снова.
                </div>
            );
        }

        return <div className={classes.wrapper}>{this.renderContent()}</div>;
    }
}

const styles = (theme: ITheme) =>
    createStyles({
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: 1 + 'rem',
            width: 450,
            minHeight: 'min-content',

            [theme.breakpoints.down('sm')]: {
                width: 'calc(100vw - 2rem)', // весь экран - 2*padding
                height: 'calc(100vh - 2rem)', // весь экран - 2*padding
                justifyContent: 'center',
            },

            ...ieFix({ display: 'block' }),
        },
        title: {
            ...title({ size: 'h2', mode: 'bottom' }),
            marginTop: 0,
            marginBottom: 0,
        },
        description: {
            textAlign: 'center',
        },
        pointsDetails: {
            display: 'flex',
            flexDirection: 'column',
            padding: 1 + 'rem',
            border: `1px solid ${theme.colors.lightGray}`,
            borderBottom: 'none',
        },
        field: {
            ...field,
            marginBottom: 0.5 + 'rem',
            paddingLeft: 1 + 'rem',
            display: 'block',
        },
        checkbox: {
            marginRight: 10,
        },
        textCenter: {
            textAlign: 'center',
        },
        submitWrapper: {
            marginTop: 16,
            display: 'flex',
        },
        submit: {
            ...button(theme),
            ...buttonAccent(theme),
            flexGrow: 1,
            fontFamily: '"Helvetica Neue", sans-serif',

            [theme.breakpoints.down('sm')]: {
                marginLeft: -1 + 'rem',
                marginRight: -1 + 'rem',
            },
        },
        successIcon: {
            fill: 'green',
        },
        error: {
            color: theme.colors.accentLight,
            fontSize: 14,
            display: 'block',
        },
        link: {
            ...reverseLink(theme.colors.linkOld),
            display: 'block',
            textDecoration: 'none',
            '&:hover': {
                color: theme.colors.linkOldHover,
            },
        },
        telegram: {
            ...reverseLink(theme.colors.accent),
            display: 'block',
            textDecoration: 'none',
            '&:hover': {
                color: theme.colors.accentLight,
            },
        },
        disclaimer: {
            textAlign: 'center',
            fontSize: 0.85 + 'rem',
            paddingLeft: 3 + 'rem',
            paddingRight: 3 + 'rem',
            marginTop: 16,
            lineHeight: 1 + 'rem',

            '& a': {
                color: theme.colors.black,
            },

            [theme.breakpoints.down('sm')]: {
                paddingLeft: 1 + 'rem',
                paddingRight: 1 + 'rem',
                marginBottom: 1 + 'rem',
            },
        },
        summary: {
            paddingTop: 0.5 + 'rem',
            paddingBottom: 0.5 + 'rem',
        },
    });

export default injectSheet(styles, 'PaymentForm')(PaymentFormPresent);
