import c from 'classnames';
import React from 'react';

type IAuthFormPhoneFieldProps = {
    phone: string;
    phoneError: string;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    validatePhone: () => boolean;
    classes: Record<'field' | 'fieldInput' | 'fieldInputInvalid' | 'error', string>;
};

const AuthFormPhoneField: React.FC<IAuthFormPhoneFieldProps> = ({
    classes,
    phone,
    phoneError,
    onChange,
    validatePhone,
}) => (
    <label className={classes.field}>
        <input
            type="text"
            value={phone}
            onChange={onChange}
            placeholder={'Введите номер телефона'}
            onBlur={validatePhone}
            className={c(classes.fieldInput, {
                [classes.fieldInputInvalid]: phoneError,
            })}
            autoComplete="tel"
        />
        <span className={classes.error}>{phoneError}</span>
    </label>
);

export default AuthFormPhoneField;
