import { postJson, IGraphQLResponse } from 'core/api/core';

const graphqlApi = <TData>(
    area: 'admin' | 'public',
    query: string,
    cookie: string = null,
    operationName: string = undefined,
) => {
    const url = `api/graphql/${area}`;
    const postData = {
        Query: query.replace(/\s+/g, ' '),
        OperationName: operationName,
    };

    return (postJson(url, postData, undefined, cookie) as any) as Promise<IGraphQLResponse<TData>>;
};

export type IGraphqlApi<TData = any> = (query: string, cookie: string) => Promise<IGraphQLResponse<TData>>;

/**
 * ВАЖНО! Если запрос выполняется на SSR, необходимо передать cookie!
 * Если запрос выполняется только на клиентской части,
 * например запрос по нажатию на кнопку или на componentDidMount(),
 * то можно передать null, браузер сам подложит все куки.
 */

// prettier-ignore
export const graphqlApiAdmin: <TData>(query: string, cookie: string) => Promise<IGraphQLResponse<TData>> =
    graphqlApi.bind(null, 'admin');

// prettier-ignore
export const graphqlApiSite: <TData>(query: string, cookie: string, operationName?: string) => Promise<IGraphQLResponse<TData>> =
    graphqlApi.bind(null, 'public');
